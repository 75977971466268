import React, { Component } from "react";
import { Link } from "react-router-dom";

class Sidebar extends Component {
  render() {
    let anchor = "#";
    let imagealt = "image";
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div className="col-lg-4 go-top">
        <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
          {/* Author Widget */}
          <div className="widget ltn__author-widget">
            <div className="ltn__author-widget-inner text-center">
              <img
                src={publicUrl + "assets/img/team/admin_sml.jpg"}
                alt="Image"
              />
              <h5>Hastings Freight</h5>
              <p>
                Learn more about what we do at Hastings Freight by reading some
                of our News stories
              </p>
              <div className="ltn__social-media">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=61550359000831"
                      target="_blank"
                      title="Facebook"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/HastingsFreight"
                      target="_blank"
                      title="Twitter"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/hastings-freight-limited"
                      target="_blank"
                      title="Linkedin"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Menu Widget (Category) */}
          {/* <div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2--- go-top">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Top Categories
            </h4>
            <ul>
              <li>
                <Link to="/blog-details">
                  Office <span>(14)</span>
                </Link>
              </li>
              <li>
                <Link to="/blog-details">
                  Charity <span>(12)</span>
                </Link>
              </li>
              <li>
                <Link to="/blog-details">
                  Expansion <span>(17)</span>
                </Link>
              </li>
              <li>
                <Link to="/blog-details">
                  Technology <span>(15)</span>
                </Link>
              </li>
            </ul>
          </div> */}
          {/* Popular Post Widget (Twitter Post) */}
          {/* <div className="widget ltn__popular-post-widget ltn__twitter-post-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Twitter Feeds
            </h4>
            <ul>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/blog-details">
                      <i className="fab fa-twitter" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <p>
                      Hastings - Don't forget to update our App to gain all the
                      new mapping featiures we've aded
                    </p>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <i className="far fa-calendar-alt" />
                            June 22, 2020
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <a href="blog-details.html">
                      <i className="fab fa-twitter" />
                    </a>
                  </div>
                  <div className="popular-post-widget-brief">
                    <p>
                      Hastings - A busy day at Hastings Freight with the
                      arrivale of 3 new trucks!
                    </p>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <i className="far fa-calendar-alt" />
                            June 22, 2020
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/blog-details">
                      <i className="fab fa-twitter" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <p>
                      Hastings - We raised £1729 for charity with YOUR help,
                      thank you so much!
                    </p>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <i className="far fa-calendar-alt" />
                            June 22, 2020
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}

          {/* Tagcloud Widget
          <div className="widget ltn__tagcloud-widget go-top">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Popular Tags
            </h4>
            <ul>
              <li>
                <Link to="/blog-grid">Popular</Link>
              </li>
              <li>
                <Link to="/blog-grid">desgin</Link>
              </li>
              <li>
                <Link to="/blog-grid">ux</Link>
              </li>
              <li>
                <Link to="/blog-grid">usability</Link>
              </li>
              <li>
                <Link to="/blog-grid">develop</Link>
              </li>
              <li>
                <Link to="/blog-grid">icon</Link>
              </li>
              <li>
                <Link to="/blog-grid">Car</Link>
              </li>
              <li>
                <Link to="/blog-grid">Service</Link>
              </li>
              <li>
                <Link to="/blog-grid">Repairs</Link>
              </li>
              <li>
                <Link to="/blog-grid">Auto Parts</Link>
              </li>
              <li>
                <Link to="/blog-grid">Oil</Link>
              </li>
              <li>
                <Link to="/blog-grid">Dealer</Link>
              </li>
              <li>
                <Link to="/blog-grid">Oil Change</Link>
              </li>
              <li>
                <Link to="/blog-grid">Body Color</Link>
              </li>
            </ul>
          </div> */}
          {/* Banner Widget */}
          {/* <div className="widget ltn__banner-widget d-none go-top">
            <Link to="/shop">
              <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
            </Link>
          </div> */}
        </aside>
      </div>
    );
  }
}

export default Sidebar;
