import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceAccountBoxes extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  What we offer
                </h6>
                <h1 className="section-title">What you can expect</h1>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tick.png"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Simple order entry or system integration</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tick.png"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Customer Notifications via SMS and Email</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tick.png"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>System and GPS tracking</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tick.png"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Instant proof of delivery with photo</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tick.png"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Online invoices and account management</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tick.png"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Quick Quotes and Transit Time checker</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceAccountBoxes;
