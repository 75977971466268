import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { useState, useEffect } from "react";
import axios from "axios";

function GetQuoteForm(props) {
  //render() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let apiurl = "https://hastings.mailshipper.co.uk";

  const [post, setPost] = useState({
    location: "",
    size: "",
    weight: "",
    companyname: "",
    yourname: "",
    position: "",
    email: "",
    phone: "",
    submitmessage: "",
    formvalidcss: "",
  });

  const handleInput = (event) => {
    setPost({ ...post, [event.target.name]: event.target.value });
  };

  const handleClick = (event) => {
    var e = document.getElementById(event.target.id);
    setPost({ ...post, [event.target.name]: e.options[e.selectedIndex].value });
  };

  function validateForm() {
    let formIsValid = true;

    if (post.location == "") {
      formIsValid = false;
    }
    if (post.size == "") {
      formIsValid = false;
    }
    if (post.weight == "") {
      formIsValid = false;
    }
    if (post.companyname == "") {
      formIsValid = false;
    }
    if (post.yourname == "") {
      formIsValid = false;
    }
    if (post.position == "") {
      formIsValid = false;
    }
    if (post.email == "") {
      formIsValid = false;
    }
    if (post.phone == "") {
      formIsValid = false;
    }
    return formIsValid;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      axios
        .post(apiurl + "/sendgetquotemail", {
          post,
        })
        .then((response) =>
          setPost({
            location: "",
            size: "",
            weight: "",
            companyname: "",
            yourname: "",
            position: "",
            email: "",
            phone: "",
            submitmessage: "Success! We will be in touch with you shortly",
            formvalidcss: "",
          })
        )
        .catch((err) => console.log(err));
    } else {
      setPost({
        ...post,
        submitMessage: "You must complete all fields before submitting",
        formvalidcss: "warning",
      });
    }
  }

  const location = useLocation();

  useEffect(() => {
    if (location.state != null) {
      setPost(location.state.post);
    }
  }, []);

  return (
    <div className="ltn__appointment-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__appointment-inner">
              <form onSubmit={handleSubmit}>
                <h2>Getting a quote from Hastings Freight</h2>
                <p>
                  <small>
                    These fields are mandatory: Company name, Your name, Your
                    position, Your email address and Your phone number
                  </small>
                </p>
                <h6>Delivery details</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item">
                      <select
                        id="ddlocation"
                        name="location"
                        onClick={handleClick}
                        value={post.location}
                      >
                        <option value="">Choose Destination</option>
                        <option value="UK">UK</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Europe">Europe</option>
                        <option value="Global">Global</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item">
                      <select
                        id="ddsize"
                        name="size"
                        onClick={handleClick}
                        value={post.size}
                      >
                        <option value="">Pallet size</option>
                        <option value="Full">Full</option>
                        <option value="Half">Half</option>
                        <option value="Quarter">Quarter</option>
                        <option value="Oversize">Oversize</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item">
                      <select
                        id="ddweight"
                        name="weight"
                        onClick={handleClick}
                        value={post.weight}
                      >
                        <option value="">Pallet weight</option>
                        <option value="Max250">0-250kg</option>
                        <option value="Max500">251-500kg</option>
                        <option value="Max1000">501-1000kg</option>
                        <option value="Over1000">1000+kg</option>
                      </select>
                    </div>
                  </div>
                </div>
                <h6>Your details</h6>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="companyname"
                        placeholder="*Your company name (mandatory)"
                        value={post.companyname}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        placeholder="*Your name (mandatory)"
                        name="yourname"
                        value={post.yourname}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="position"
                        placeholder="*Your position (mandatory)"
                        value={post.position}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                <h6>Contact details</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="email"
                        placeholder="* Your email address (mandatory)"
                        value={post.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        placeholder="* Your phone number (mandatory)"
                        value={post.phone}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="alert alert-warning d-none" role="alert">
                  Please note that the date and time you requested may not be
                  available. We will contact you to confirm your actual
                  appointment details.
                </div>
                <div className="btn-wrapper text-center mt-30">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    Submit form
                  </button>
                  <br />
                  <br />
                  <label name="submitresponse" className="ltn__lumo-color">
                    {post.submitmessage}
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // }
}

export default GetQuoteForm;
