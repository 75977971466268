import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

class GeneralEnquiryForm extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__appointment-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__appointment-inner">
                <form action="#">
                  <h2>Enquire about Hastings Freight</h2>
                  <p>
                    <small>
                      These fields are mandatory: Company name, Your name, Your
                      position, Your email address and Your phone number
                    </small>
                  </p>
                  <h6>Your details</h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="ltn__name"
                          placeholder="*Your company name (mandatory)"
                        />
                      </div>
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          placeholder="*Your name (mandatory)"
                          defaultValue={""}
                        />
                      </div>
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          placeholder="*Your position (mandatory)"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <h6>Contact details</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="ltn__name"
                          placeholder="* Your email address (mandatory)"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="ltn__name"
                          placeholder="* Your phone number (mandatory)"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="ltn__name"
                          placeholder="Company Registration Number"
                        />
                      </div>
                    </div>
                  </div>
                  <h6>Terms &amp; Conditions Acceptance</h6>
                  <p>
                    Checking the box below will display our Terms &amp;
                    Conditions which must be accepted before submitting this
                    form. Upon checking the box you are requesting to open an
                    account under agreed terms subject to credit check.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="checkbox-item">
                        <strong>
                          I accept as an authorised representative of my company
                        </strong>
                        <Popup
                          trigger={<input type="checkbox" />}
                          position="center center"
                          modal
                        >
                          {(close) => (
                            <div className="modffal">
                              <button className="close" onClick={close}>
                                &times;
                              </button>
                              <div className="header"> Modal Title </div>
                              <div className="content">
                                {" "}
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Atque, a nostrum. Dolorem,
                                repellat quidem ut, minima sint vel eveniet
                                quibusdam voluptates delectus doloremque,
                                explicabo tempore dicta adipisci fugit amet
                                dignissimos?
                                <br />
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Consequatur sit commodi beatae
                                optio voluptatum sed eius cumque, delectus saepe
                                repudiandae explicabo nemo nam libero ad,
                                doloribus, voluptas rem alias. Vitae?
                              </div>
                              <div className="actions">
                                <button
                                  className="button"
                                  onClick={() => {
                                    console.log("modal closed ");
                                    close();
                                  }}
                                >
                                  close modal
                                </button>
                              </div>
                            </div>
                          )}
                        </Popup>

                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>

                  <div className="alert alert-warning d-none" role="alert">
                    Please note that the date and time you requested may not be
                    available. We will contact you to confirm your actual
                    appointment details.
                  </div>
                  <div className="btn-wrapper text-center mt-30">
                    <button
                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      type="submit"
                    >
                      Submit form
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralEnquiryForm;
