import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import parse from "html-react-parser";

class CareersDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__page-details-area ltn__portfolio-details-area mb-105">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="ltn__page-details-inner ltn__portfolio-details-inner">
                <div className="ltn__blog-img">
                  <img
                    src={publicUrl + "assets/img/others/careers.jpg"}
                    alt="Hastings office staff"
                  />
                </div>
                <h3>Careers at Hastings Freight</h3>
                <p>
                  Our staff are the driving force behind our success. Their
                  enthusiasm and professionalism have helped us to become the
                  pallet distribution business we are today. Fully motivated and
                  dedicated to offering world class customer service, they
                  ensure our pallet delivery network meets the needs of
                  customers and their customers alike every time. Working
                  towards our vision to be “The Pallet Network of natural choice
                  for the distribution of palletised express freight”, our
                  workforce strives to achieve the very best results while
                  maintaining and upholding our key core values.
                </p>
                <h5>EQUAL OPPORTUNITIES</h5>
                <p>
                  These core values make up who we are, what we represent and
                  ultimately how we work. With a commitment to creating a fair
                  and diverse working environment, we look to ensure every
                  employee is treated equally, irrespective of their race,
                  colour, ethnic or national origin, religious belief, political
                  opinion or affiliation, gender, marital status, sexual
                  orientation, age or disability.
                </p>
                <h5>INVESTORS IN PEOPLE</h5>
                <p>
                  Appreciating the integral role our staff play, we recognise
                  the need to develop our workers and help them to be the very
                  best they can be. We're committed to attracting, developing
                  and retaining the talented people we need to ensure our pallet
                  delivery experience remains the very best in the market. Many
                  of our staff receive long service status and we are proud to
                  grow our staff through training and promote from within.
                </p>
                <h5>HOW TO APPLY</h5>
                <p>
                  Hastings Freight is always interested in talented people,
                  please feel free to email us at hr@hastingsfreight.co.uk
                  enclosing your CV. Our HR team will consider your profile for
                  any appropriate any roles and get in touch.
                </p>
                <div className="row">
                  <div id="george" className="col-lg-6">
                    <div className="ltn__testimonial-item ltn__testimonial-item-3">
                      <div className="ltn__testimonial-img">
                        <img
                          src={publicUrl + "assets/img/team/george.jpg"}
                          alt="Image"
                        />
                      </div>
                      <div className="ltn__testimoni-info">
                        <p>
                          "I started work at HF in our accounts department and
                          have done pretty much every role, from plumbing to
                          driving. I've seen Hastings grow and am proud to be
                          part of a team that is focused on the difference we
                          can make to both business and our environment. We're
                          now focused on the future as we move to a more
                          sustainable way of working."{" "}
                        </p>
                        <div className="ltn__testimoni-info-inner">
                          <div className="ltn__testimoni-img">
                            <img
                              src={publicUrl + "assets/img/testimonial/1.jpg"}
                              alt="Image"
                            />
                          </div>
                          <div className="ltn__testimoni-name-designation">
                            <h4>George Hewitt</h4>
                            <h6>Managing Director</h6>
                          </div>
                        </div>
                        <div className="ltn__testimoni-bg-icon">
                          <i className="far fa-comments" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="luke" className="col-lg-6">
                    <div className="ltn__testimonial-item ltn__testimonial-item-3">
                      <div className="ltn__testimonial-img">
                        <img
                          src={publicUrl + "assets/img/team/luke.jpg"}
                          alt="Image"
                        />
                      </div>
                      <div className="ltn__testimoni-info">
                        <p>
                          “I've been with Hastings Freight for 8 years now,
                          experiencing many roles during my time. My current
                          role is Business Development Executive. My focus is on
                          generating new sales and customer retention. I also
                          help oversee the company's social media accounts.
                          Hastings Freight has a good working environment and
                          family feel to it”.
                        </p>
                        <div className="ltn__testimoni-info-inner">
                          <div className="ltn__testimoni-img">
                            <img
                              src={publicUrl + "assets/img/testimonial/1.jpg"}
                              alt="Image"
                            />
                          </div>
                          <div className="ltn__testimoni-name-designation">
                            <h4>Luke Newman</h4>
                            <h6>Business Development</h6>
                          </div>
                        </div>
                        <div className="ltn__testimoni-bg-icon">
                          <i className="far fa-comments" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="georgia" className="col-lg-6">
                    <div className="ltn__testimonial-item ltn__testimonial-item-3">
                      <div className="ltn__testimonial-img">
                        <img
                          src={publicUrl + "assets/img/team/georgia.jpg"}
                          alt="Image"
                        />
                      </div>
                      <div className="ltn__testimoni-info">
                        <p>
                          “I'm the Customer Services Manger here at Hastings
                          Freight with 10 years' experience in the industry. I
                          have a real love for logistics and the challenges it
                          brings daily. I started at Hastings in a customer
                          service role, moving to a team leader and more
                          recently securing promotion to manager.”.
                        </p>
                        <p>
                          "Being very service minded I am always striving to
                          provide first class service to our customers. My core
                          values are respect, honesty, consistency and
                          reliability, I'd like to think this reflects within
                          our team and service here. At Hastings Freight we
                          promote an inclusive workplace and the people are what
                          really makes it a great place to be. "
                        </p>
                        <div className="ltn__testimoni-info-inner">
                          <div className="ltn__testimoni-img">
                            <img
                              src={publicUrl + "assets/img/testimonial/1.jpg"}
                              alt="Image"
                            />
                          </div>
                          <div className="ltn__testimoni-name-designation">
                            <h4>Georgia Dickson</h4>
                            <h6>Customer Services Manger</h6>
                          </div>
                        </div>
                        <div className="ltn__testimoni-bg-icon">
                          <i className="far fa-comments" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="ash" className="col-lg-6">
                    <div className="ltn__testimonial-item ltn__testimonial-item-3">
                      <div className="ltn__testimonial-img">
                        <img
                          src={publicUrl + "assets/img/team/ash.jpg"}
                          alt="Image"
                        />
                      </div>
                      <div className="ltn__testimoni-info">
                        <p>
                          “My journey in Hastings Freight, over the last 9 years
                          has been one of growth, taking on different roles
                          within the operations team. I started off scanning
                          paperwork and am now responsible for Transport and
                          Compliance, keeping the wheels moving on our fleet and
                          supporting the team and drivers to get the job done. 9
                          years later I still love the instantaneous buzz of
                          problem solving and now using these skills to help the
                          company grow and move forward. Hastings Freight, and
                          the people here are an enormous part of my life, the
                          company's cultures intertwine with my own, and they
                          always want to help you become the best version of
                          yourself. Onwards and upwards as we say!”
                        </p>
                        <div className="ltn__testimoni-info-inner">
                          <div className="ltn__testimoni-img">
                            <img
                              src={publicUrl + "assets/img/testimonial/1.jpg"}
                              alt="Image"
                            />
                          </div>
                          <div className="ltn__testimoni-name-designation">
                            <h4>Ash Roberts</h4>
                            <h6>Transport & Compliance Manager</h6>
                          </div>
                        </div>
                        <div className="ltn__testimoni-bg-icon">
                          <i className="far fa-comments" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar-area ltn__right-sidebar">
                {/* Menu Widget */}
                <div className="widget-2 ltn__menu-widget ltn__menu-widget-2 text-uppercase">
                  <ul className="go-top">
                    <li>
                      <Link to="/your-career">
                        Careers{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/careers#george">
                        George Hewitt{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/careers#luke">
                        Luke Newman{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/careers#georgia">
                        Georgia Dickson{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/careers#ash">
                        Ash Roberts{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CareersDetails;
