import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ContactInfo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__contact-address-area mb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/10.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Email Address</h3>
                <p>info@hastingsfreight.co.uk</p>
                <br />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/11.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Phone Number</h3>
                <p>
                  <b>TEL: </b>0845 673 2030 <br />
                  <b>Fax</b> 0845 673 2040
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/12.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Office Address</h3>
                <p>
                  10 High Hazels Road, Barlborough Links, <br />
                  Chesterfield, S43 4UZ
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <Link to="/get-started" className="highlightLink">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img
                      src={publicUrl + "assets/img/icons/account.png"}
                      alt="Icon Image"
                    />
                  </div>
                  <h3>Open an account</h3>
                  <p>Click Here to enquire about opening an account</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/ask.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Ask a question</h3>
                <p>
                  Have a question for us? Fill out the form below and we'll get
                  right back to you
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <Link to="/careers">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img
                      src={publicUrl + "assets/img/icons/team.png"}
                      alt="Icon Image"
                    />
                  </div>
                  <h3>Join our Team</h3>
                  <p>
                    Want to join our amzing team? Click Here to learn about our
                    careers.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
