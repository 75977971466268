import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Social extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__social-media">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61550359000831"
              title="Facebook"
              target="_blank"
            >
              <i className="fab fa-facebook" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/HastingsFreight"
              title="Twitter"
              target="_blank"
            >
              <i className="fab fa-twitter" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/hastings-freight-limited"
              title="LinkedIn"
              target="_blank"
            >
              <i className="fab fa-linkedin" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Social;
