import React from "react";
import Navbar from "./global-components/navbar-v3";
import PageHeader from "./global-components/page-header";
import ServiceAccountBoxes from "./section-components/service-account-boxes";
import ServiceAccountContent from "./section-components/service-account-content.js";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const ServiceAccountPage = () => {
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Online Account Management"
        subheader="Services"
      />
      <ServiceAccountContent />
      <ServiceAccountBoxes />
      {/* <BlogSlider sectionClass="pt-120" /> */}
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default ServiceAccountPage;
