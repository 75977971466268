import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceTransportBoxes extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  What we offer
                </h6>
                <h1 className="section-title">Why choose Hastings Freight?</h1>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={
                        publicUrl + "assets/img/service/boxes/bespoke_team.jpg"
                      }
                      alt="Hastings office staff"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Experienced team with nationwide contacts</h3>
                  <p>On hand to make transportation smooth and simple</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={
                        publicUrl +
                        "assets/img/service/boxes/bespoke_fullhalf.jpg"
                      }
                      alt="Hastings truck on the road"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Full and half loads on dedicated vehicles</h3>
                  <p>Flexible solutions from a single pallet to full loads</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={
                        publicUrl +
                        "assets/img/service/boxes/bespoke_warehouse.jpg"
                      }
                      alt="Hastings warehouse racking"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Storage solutions tailored to you</h3>
                  <p>On site or same day collection from local partners</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceTransportBoxes;
