import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";

class PrivacyPolicy extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    return (
      <div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
        <div className="container">
          <div className="row">
            <h5>Privacy Policy</h5>
            <p>
              Hastings Freight Limited (“<strong>we</strong>“) are committed to
              protecting and respecting your privacy in accordance with the
              European General Data Protection Regulations. This Privacy Policy
              provides an explanation as to what happens to any personal
              information that you provide to us, or that we collect from you,
              and your options regarding the ways in which your personal data is
              used. Please read the following carefully to understand our views
              and practices regarding your personal information and how we will
              treat it. By visiting www.hastingsfreight.co.uk (“
              <strong>our site</strong>”) you are accepting and consenting to
              the practices described in this policy. If you have any requests
              concerning your personal information or any queries with regard to
              our data processing practices, please contact us. Our General
              Manager is responsible for overseeing questions in relation to
              this privacy policy.
            </p>
            <p>
              For the purpose of the Data Protection Act 1998 (“
              <strong>the Act</strong>“) and this policy, the data controller is
              Hastings Freight Limited, 10 High Hazels Road, Barlborough,
              Chesterfield, S43 4UZ
            </p>
            <p>
              <strong>INFORMATION WE MAY COLLECT FROM YOU&nbsp;</strong>
            </p>
            <p>We may collect and process the following data about you:</p>
            <ul>
              <li>
                <strong>Information you give us.</strong>&nbsp;You may give us
                personal information about you by filling in forms on our site
                or by corresponding with us by telephone, e-mail or otherwise.
                The information you give us may include your name, address,
                e-mail address and phone number, financial and credit card
                information, personal description and photograph, information
                regarding your shipment such as the details and nature of the
                products being delivered, their source and their destination
              </li>
              <li>
                <strong>Information we collect about you.</strong>&nbsp;With
                regard to each of your visits to our site we may automatically
                collect the following information:
                <ul>
                  <li>
                    technical information, including the Internet protocol (IP)
                    address used to connect your computer to the Internet,
                    browser type and version, time zone setting, browser plug-in
                    types and versions, operating system and platform;
                  </li>
                  <li>
                    information about your visit, including the full Uniform
                    Resource Locators (URL) clickstream to, through and from our
                    site (including date and time); page response times,
                    download errors, length of visits to certain pages, page
                    interaction information (such as scrolling, clicks, and
                    mouse-overs), and methods used to browse away from the page
                    and any phone number used to call us.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>Information we receive from other sources.</strong>
              &nbsp;We may receive information about you if you use any of the
              other websites we operate or the other services we provide. In
              this case we will have informed you when we collected that data
              that it may be shared internally and combined with data collected
              on this site. We are also working closely with third parties
              (including, for example, business partners, sub-contractors,
              payment and delivery services, advertising networks, analytics
              providers, search information providers, credit reference
              agencies) and may receive information about you from them.
            </p>
            <p>
              <strong>COOKIES&nbsp;</strong>
            </p>
            <p>
              The internet pages partly use so-called cookies. Cookies do not
              harm your computer and do not contain viruses. Cookies serve to
              make our offer more user-friendly, effective and secure. Cookies
              are small text files that are stored on your computer and stored
              by your browser.
            </p>
            <p>
              Most of the cookies we use are so-called “session cookies”. They
              are automatically deleted after your visit. Other cookies remain
              stored on your device until you delete them. These cookies allow
              us to recognize your browser the next time you visit.
            </p>
            <p>
              You can set your browser so that you are informed about the
              setting of cookies and cookies only in individual cases allow, the
              acceptance of cookies for certain cases or generally exclude and
              activate the automatic deletion of cookies when closing the
              browser. Disabling cookies may limit the functionality of this
              website.
            </p>
            <p>
              <strong>USES MADE OF THE INFORMATION&nbsp;</strong>
            </p>
            <p>We use information held about you in the following ways:</p>
            <ul>
              <li>
                <strong>Information you give to us.</strong>&nbsp;We will use
                this information:
                <ul>
                  <li>
                    to carry out our obligations arising from any contracts
                    entered into between you and us and to provide you with the
                    information and services that you request from us;
                  </li>
                  <li>
                    to provide you with information about other goods and
                    services we offer that are similar to those that you have
                    already purchased or enquired about;
                  </li>
                  <li>to notify you about changes to our services;</li>
                  <li>
                    to process any application for employment you might make to
                    us;
                  </li>
                  <li>
                    to ensure that content from our site is presented in the
                    most effective manner for you and for your computer;
                  </li>
                  <li>
                    to process any enquiry that you may submit to us via
                    electronic means, including any online forms.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Information we collect about you.</strong>&nbsp;We will
                use this information:
                <ul>
                  <li>
                    to administer our site and for internal operations,
                    including troubleshooting, data analysis, testing, research,
                    statistical and survey purposes;
                  </li>
                  <li>
                    to improve our site to ensure that content is presented in
                    the most effective manner for you and for your computer;
                  </li>
                  <li>
                    to allow you to participate in any interactive features of
                    our service;
                  </li>
                  <li>
                    as part of our efforts to keep our site safe and secure;
                  </li>
                  <li>
                    to measure or understand the effectiveness of advertising we
                    serve to you and others, and to deliver relevant advertising
                    to you;
                  </li>
                  <li>
                    To establish whether any of the services we may offer from
                    time to time may be of interest to you.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Information we may receive from other sources.</strong>
                &nbsp;We may combine this information with information you give
                to us and information we collect about you. We may us this
                information and the combined information for the purposes set
                out above (depending on the types of information we receive).
              </li>
            </ul>
            <p>
              <strong>
                The personal data we collect will be used for the following
                purposes:
              </strong>
            </p>
            <ul>
              <li>Sending direct marketing regarding our services</li>
              <li>
                Tracking when emails have been opened, how often, and whether
                links have been clicked within the email (and also when
                unsubscribing)
              </li>
              <li>
                Initiating contact on the basis of tracking this information
              </li>
              <li>
                Sharing this information, when necessary, internally and with
                network members
              </li>
            </ul>
            <p>
              <strong>Our legal basis for processing the personal data:</strong>
            </p>
            <ul>
              <li>Performance of a contract with you.</li>
            </ul>
            <ul>
              <li>Consent (where granted)</li>
              <li>
                Legitimate Interest (where consent has not been granted nor
                refused)
              </li>
            </ul>
            <p>
              <strong>
                Any legitimate interests pursued by us, or third parties, are as
                follows:
              </strong>
            </p>
            <ul>
              <li>
                A legitimate interest assessment has been completed on your
                data, and we believe that you have an interest in the service we
                are communicating
              </li>
              <li>
                Our segmentation process has highlighted your industry as one
                which can benefit from the service, we are communicating to
              </li>
              <li>
                We will only process your data if we believe that the message,
                we are communicating will be of interest to your organisationWe
                will only collect, store and process the personal data we need
                to perform these direct marketing actions:
                <ul>
                  <li>Name</li>
                  <li>Email address</li>
                </ul>
              </li>
            </ul>
            <p>
              If you send us enquiries via the contact form, your details from
              the enquiry form, including the contact details you provided
              there, will be stored in order to process the request and in case
              of follow-up questions. We will not share this information without
              your consent.
            </p>
            <p>
              The processing of the data entered into the contact form is
              therefore exclusively based on your consent (Art. 6 (1) lit.
              DSGVO). You can revoke this consent at any time. An informal
              message by e-mail to us is sufficient. The legality of the data
              processing operations carried out until the revocation remains
              unaffected by the revocation.
            </p>
            <p>
              The information you provide in the contact form will remain with
              us until you ask us to delete it, revoke your consent to storage
              or delete the purpose for data storage (for example, after your
              request has been processed). Mandatory statutory provisions –
              especially retention periods – remain unaffected.
            </p>
            <p>
              <strong>APPLICATIONS FOR EMPLOYMENT&nbsp;</strong>
            </p>
            <p>
              We collect and process the personal data of applicants for the
              purpose of processing the application process. The processing can
              also be done electronically.
            </p>
            <p>
              This is especially the case if an applicant submits the relevant
              application documents to us electronically, for example by e-mail
              or via a web form located on the website.
            </p>
            <p>
              If a contract of employment results from the application, the
              transmitted data will be stored for the purpose of executing the
              employment relationship in compliance with the statutory
              provisions.
            </p>
            <p>
              If the application does not result in a contract of employment,
              the application documents will be deleted six months after the
              appointment has been completed, provided that deletion does not
              prejudice any other legitimate interests of the controller. Other
              legitimate interest in this sense, for example, a burden of proof
              in a procedure under the General Equal Treatment Act (AGG). After
              approval by the applicant, the data can also be stored longer.
            </p>
            <p>
              <strong>SOCIAL MEDIA TOOLS</strong>
            </p>
            <p>
              <strong>Twitter</strong>
            </p>
            <p>
              Functions of the Twitter service are integrated on our sites.
              These features are available through Twitter Inc., 1355 Market
              Street, Suite 900, San Francisco, CA 94103, USA. By using Twitter
              and the “Re-Tweet” function, the websites you visit are linked to
              your Twitter account and shared with other users. This data is
              also transmitted to Twitter. We point out that we as the provider
              of the pages are not aware of the content of the transmitted data
              and their use by Twitter. Further information can be found here in
              the privacy policy of Twitter at https://twitter.com/privacy.
            </p>
            <p>
              You can change your privacy settings on Twitter in the account
              settings at:&nbsp;
              <a href="https://twitter.com/account/settings">
                https://twitter.com/account/settings
              </a>
              .
            </p>
            <p>
              <strong>Google Analytics</strong>
            </p>
            <p>
              This website uses the “Google Analytics” service provided by
              Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043,
              USA) to analyze users’ website usage. The service uses “cookies” –
              text files stored on your device. The information collected by the
              cookies is usually sent to a Google server in the US and stored
              there.
            </p>
            <p>
              On this website accesses the IP anonymization. The IP address of
              users is shortened within the member states of the EU and the
              European Economic Area. This reduction eliminates the personal
              reference of your IP address. Under the terms of the agreement,
              which website operators have entered into with Google Inc., they
              use the information collected to compile an evaluation of website
              activity and site activity, and provide Internet-related services.
            </p>
            <p>
              You have the option to prevent the storage of cookies on your
              device by making the appropriate settings in your browser. There
              is no guarantee that you will be able to access all features of
              this website without restriction if your browser does not allow
              cookies.
            </p>
            <p>
              Furthermore, you can use a browser plug-in to prevent the
              information collected by cookies (including your IP address) from
              being sent to Google Inc. and used by Google Inc.
            </p>
            <p>
              Here’s more information on Google Inc’s data usage:&nbsp;
              <a href="https://support.google.com/analytics/answer/6004245?hl=en">
                https://support.google.com/analytics/answer/6004245?hl=en
              </a>
            </p>
            <p>
              <strong>Google Maps</strong>
            </p>
            <p>
              This site uses the mapping service Google Maps via an API.
              Provider is Google Inc., 1600 Amphitheater Parkway, Mountain View,
              CA 94043, USA.
            </p>
            <p>
              To use the features of Google Maps, it is necessary to save your
              IP address. This information is usually transmitted to and stored
              on a Google server in the United States. The provider of this site
              has no influence on this data transfer.
            </p>
            <p>
              The use of Google Maps is in the interest of an attractive
              presentation of our online offers and an easy findability of the
              places we have indicated on the website. This constitutes a
              legitimate interest within the meaning of Art. 6 para. 1 lit. f
              DSGVO.
            </p>
            <p>
              For more information on how to handle user data, please refer to
              Google’s Privacy Policy:&nbsp;
              <a href="https://www.google.com/intl/en/policies/privacy/">
                https://www.google.com/intl/en/policies/privacy/
              </a>
              .
            </p>
            <p>
              <strong>DISCLOSURE OF YOUR INFORMATION</strong>
            </p>
            <p>
              We may share your personal information with your consent with any
              member of our group, which means our subsidiaries, any holding
              company of us and its subsidiaries, in each case as defined in
              section 1159 of the UK Companies Act 2006.
            </p>
            <p>
              We may share your information with selected third parties
              including:
            </p>
            <ul>
              <li>
                Business partners, suppliers and sub-contractors for the
                performance of any contract we enter into with them or you;
              </li>
              <li>
                Analytics and search engine providers that assist us in the
                improvement and optimisation of our site;
              </li>
              <li>
                Credit reference agencies for the purpose of assessing your
                credit score where this is a condition of us entering into a
                contract with you.We may disclose your personal information to
                third parties:
              </li>
              <li>
                In the event that we sell any of our group’s business or assets,
                in which case we may disclose your personal data to the
                prospective buyer of such business or assets.
              </li>
              <li>
                If any company in our group is, or substantially all of its
                assets are, acquired by a third party, in which case personal
                data gathered from you may be one of the transferred assets.
              </li>
              <li>
                If we are under a duty to disclose or share your personal data
                in order to comply with any legal obligation, or in order to
                enforce or apply our terms of use or terms and conditions of
                supply and other agreements, or to protect the rights, property,
                or safety of our employees, our customers, or others. This may
                include exchanging information with other companies and
                organisations for the purposes of fraud detection or prevention,
                and credit risk reduction.
              </li>
            </ul>
            <p>
              <strong>WHERE WE STORE YOUR PERSONAL DATA</strong>
            </p>
            <p>
              The data that we collect from you may be transferred to, and
              stored at, a destination outside of the United Kingdom. &nbsp;It
              may also be processed by staff operating outside the United
              Kingdom working to fulfil the Import or Export of goods through
              the Palletways Network covering mainland Europe or for one of our
              suppliers. Such staff maybe engaged in, among other things, the
              fulfilment of your contract with us, the processing of your
              payment details and the provision of support services to us. By
              submitting your personal data, you agree to this transfer, storing
              or other forms of processing. We will take all steps reasonably
              necessary to ensure that your data is treated securely and in
              accordance with this privacy policy and the Act.
            </p>
            <p>
              All information you provide to us is stored on our secure servers.
              Any payment transactions will be encrypted using SSL technology.
            </p>
            <p>
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although we will do our best to protect
              your personal data, we cannot guarantee the security of your data
              transmitted to our site; any transmission is at your own risk.
              Once we have received your information, we will use appropriate
              procedures and security features to try to prevent unauthorised
              access.
            </p>
            <p>
              <strong>YOUR RIGHTS</strong>
            </p>
            <p>
              You have the right to ask us not to process your personal data for
              marketing purposes. We will usually inform you (before collecting
              your data) if we intend to use your data for such purposes or if
              we intend to disclose your information to any third party for such
              purposes. You can exercise your right to prevent such processing
              by checking certain boxes on the forms we use to collect your
              data. You can also exercise the right at any time by contacting us
              at&nbsp;
              <a href="mailto:data_protection@palletways.com">
                itsupport@hastingsfreight.co.uk
              </a>
            </p>
            <p>
              Our site may, from time to time, contain links to and from the
              websites of third parties. If you follow a link to any of these
              websites, please note that these websites have their own privacy
              policies and that we do not accept any responsibility or liability
              for these policies or how such third parties may process your
              personal information. Please check these policies before you
              submit any personal data to these websites. We invite you to
              follow this link to view more information on your rights{" "}
              <a href="https://ico.org.uk/global/privacy-notice/your-data-protection-rights/">
                Your Data Protection Rights
              </a>
            </p>
            <p>
              <strong>PROTECTION OF YOUR DATA</strong>
            </p>
            <p>
              The operators of these pages take the protection of your personal
              data very seriously. We treat your personal data confidentially
              and in accordance with the statutory data protection regulations
              and this privacy policy.
            </p>
            <p>
              If you use this website, various personal data will be collected.
              Personal information is information that personally identifies
              you.
            </p>
            <p>
              Please note that data transmission over the Internet (for example,
              when communicating via e-mail) may have security vulnerabilities.
              A complete protection of the data from access by third parties is
              not possible.
            </p>
            <p>
              <strong>REVOCATION OF YOUR CONSENT TO DATA PROCESSING</strong>
            </p>
            <p>
              Many data processing operations are only possible with your
              express consent. You can revoke an existing consent at any time.
              Please email this request to&nbsp;
              <a href="mailto:data_protection@palletways.com">
                itsupport@hastingsfreight.co.uk
              </a>
              &nbsp;– an informal message to us is sufficient. The legality of
              the data processing carried out until the revocation remains
              unaffected by the revocation.
            </p>
            <p>
              <strong>RIGHT TO DATA PORTABILITY</strong>
            </p>
            <p>
              You have the right to have data, which we process automatically on
              the basis of your consent or in fulfilment of a contract, handed
              out to you or to a third party in a standard, machine-readable
              format. If you require the direct transfer of data to another
              person in charge, this will only be done to the extent technically
              feasible.
            </p>
            <p>
              <strong>INFORMATION, BLOCKING, DELETION</strong>
            </p>
            <p>
              You have the right to free information on your stored personal
              data, their origin and recipient and the purpose of the data
              processing and, if necessary, a right to rectification, blocking
              or deletion of this data.&nbsp; Please direct any such request
              to&nbsp;
              <a href="mailto:data_protection@palletways.com">
                itsupport@hastingsfreight.co.uk
              </a>
              .
            </p>
            <p>
              <strong>CHANGES TO OUR PRIVACY POLICY</strong>
            </p>
            <p>
              Any changes we may make to this policy in the future will be
              posted on this page. Please check back frequently to see any
              updates or changes to this policy.
            </p>
            <p>
              <strong>CONTACT</strong>
            </p>
            <p>
              Questions, comments and requests regarding this privacy policy are
              welcomed and should be addressed to&nbsp;
              <a href="mailto:data_protection@palletways.com">
                itsupport@hastingsfreight.co.uk
              </a>
              .
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
