import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class TeamV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__team-area pt-110--- pb-90">
        <div className="container">
          <div className="row justify-content-left go-top">
            <img
              src={publicUrl + "assets/img/team/join_our_team.jpg"}
              alt="Image"
            />
          </div>
          <br />
          <div className="row justify-content-left go-top">
            <div className="col-lg-6">
              <div className="ltn__testimonial-item ltn__testimonial-item-3">
                <div className="ltn__testimoni-info">
                  <p>
                    A fast paced department where no two days are the same. Our
                    traffic team manage the day to day movements of our vehicles
                    while optimizing efficiency. Working to meet our customer
                    collection needs whilst liaising across the networks to
                    ensure we meet key performance indicators. Our dedicated
                    Customer Service team provide first class support to our
                    customers, evolving as their needs change. Problem solving
                    to ensure we build long term relationships with all clients.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__list-item-half">
                      <i className="flaticon-maps-and-location" />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h4>Our Operations team</h4>
                    </div>
                  </div>
                  <div className="ltn__testimoni-bg-icon">
                    <i className="far fa-comments" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ltn__testimonial-item ltn__testimonial-item-3">
                <div className="ltn__testimoni-info">
                  <p>
                    Split into two sections we have day drivers and night
                    drivers. The face of our company, our C & D day drivers
                    collect and deliver to our customers in the South Yorkshire
                    and Derbyshire area. We have a 30+ fleet ranging from 7.5t
                    vehicles to 44t articulated lorries. Our Night team handle
                    the trunking to the regional sortation hubs ensuring freight
                    is distributed in good time to allow efficient planning.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__list-item-half">
                      <i className="flaticon-operator" />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h4>Our Drivers</h4>
                      {/* <h6>Mark, Barry, Jack, George</h6> */}
                    </div>
                  </div>
                  <div className="ltn__testimoni-bg-icon">
                    <i className="far fa-comments" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row justify-content-left go-top">
            <div className="col-lg-6">
              <div className="ltn__testimonial-item ltn__testimonial-item-3">
                <div className="ltn__testimoni-info">
                  <p>
                    Our operation is 24 hours and starts with the movement of
                    pallets from our collection vehicles from the sortation
                    HUBs, we have to coordinate at a fast pace, unloading and
                    loading our vehicles ensuring a quick turn around allowing
                    them to service our customers in the local area.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__list-item-half">
                      <i className="flaticon-package" />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h4>Our Warehouse team</h4>
                    </div>
                  </div>
                  <div className="ltn__testimoni-bg-icon">
                    <i className="far fa-comments" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ltn__testimonial-item ltn__testimonial-item-3">
                <div className="ltn__testimoni-info">
                  <p>
                    Our finance team are small but perfectly formed. They handle
                    all financial functions of the business including providing
                    support to our customers. They are integral to the day to
                    day running of Hastings Freight and have a wealth of
                    experience and knowledge.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__list-item-half">
                      <i className="flaticon-financial" />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h4>Our Finance team</h4>
                      {/* <h6>Amanda, Harriet, Micheal</h6> */}
                    </div>
                  </div>
                  <div className="ltn__testimoni-bg-icon">
                    <i className="far fa-comments" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row justify-content-left go-top">
            <div className="col-lg-6">
              <div className="ltn__testimonial-item ltn__testimonial-item-3">
                <div className="ltn__testimoni-info">
                  <p>
                    Our department are passionate about logistics. Our role is
                    to understand customer needs, both existing and new, and
                    tailor our service to meet them. Whether we're meeting in
                    person, online or having a discussion over the phone,
                    customer interaction is what we love.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__list-item-half">
                      <i className="flaticon-location" />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h4>Our Business Development team</h4>
                      {/* <h6>Luke, George, Sarah</h6> */}
                    </div>
                  </div>
                  <div className="ltn__testimoni-bg-icon">
                    <i className="far fa-comments" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamV2;
