import React from "react";
import Navbar from "./global-components/navbar-v3";
import PageHeader from "./global-components/page-header";
import ServiceTransportContent from "./section-components/service-transport-content";
import ServiceTransportBoxes from "./section-components/service-transport-boxes";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const ServiceTransportPage = () => {
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Transport &amp; Storage Solutions"
        subheader="Services"
      />
      <ServiceTransportContent />
      <ServiceTransportBoxes />
      {/* <BlogSlider sectionClass="pt-120" /> */}
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default ServiceTransportPage;
