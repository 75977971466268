import React from "react";

const ModalPopUp = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <div class="pum-content popmake-content">
          <h2>Road Haulage Association Limited</h2>
          <h3>CONDITIONS OF CARRIAGE 2020</h3>
          <h5>Effective 1 September 2020</h5>
          <p>
            <strong>
              PLEASE NOTE THAT THE CUSTOMER WILL NOT IN ALL CIRCUMSTANCES BE
              ENTITLED TO COMPENSATION, OR TO FULL COMPENSATION, FOR ANY LOSS
              AND MAY BE SUBJECT TO CERTAIN OBLIGATIONS AND INDEMNITIES. THE
              CUSTOMER SHOULD THEREFORE SEEK PROFESSIONAL ADVICE AS TO
              APPROPRIATE INSURANCE COVER TO BE MAINTAINED WHILE CONSIGNMENTS
              ARE IN TRANSIT.
            </strong>
          </p>
          <p>
            <strong>Company Stamp or details</strong>
            <br />
            HASTINGS FREIGHT LTD
            <br />
            10 High Hazels Road Barlborough Chesterfield Derbyshire
            <br />
            S43 4UZ
          </p>
          <p>
            <strong>RHA membership number:</strong> 0024029‐000
          </p>
          <p>
            (hereinafter referred to as “the Carrier”) is not a common carrier
            and accepts goods for carriage only upon that condition and on the
            conditions set out below (the Conditions). No servant or agent of
            the Carrier is permitted to alter or vary these Conditions in any
            way unless expressly authorised to do so in writing by a Director
            of, Principal of, or Partner in the Carrier, or by another person
            separately authorised by such a person in writing. If any provision
            or part‐provision of these Conditions is or becomes invalid, illegal
            or unenforceable, it shall be deemed modified to the minimum extent
            necessary to make it valid, legal and enforceable. If such
            modification is not possible, the relevant provision or part‐
            provision shall be deemed deleted. Any modification to or deletion
            of a provision or part‐provision under this clause shall not affect
            the validity and enforceability of the rest of these Conditions.
            These Conditions apply to the Contract to the exclusion of any other
            terms that the Customer seeks to impose, have agreed or incorporate,
            and to the exclusion of any terms which might be implied by trade,
            custom, practice or course of dealing. It is expressly stated to be
            the Customer's responsibility to read and understand these
            Conditions which will form the basis of the Contract under which any
            claims or disputes are settled. Customers are recommended to take
            professional advice and must arrange adequate insurance to provide
            full cover for the Consignment, and any liabilities they may be
            under in respect of it, when the Consignment is in transit.
          </p>
          <p>
            <strong>1. Definitions</strong>
          </p>
          <p>In these Conditions:</p>
          <p>
            “Customer” means the person or company who contracts for the
            services of the Carrier, including any other carrier who gives a
            Consignment to the Carrier for carriage.
          </p>
          <p>
            “Contract” means the contract of carriage between the Customer and
            the Carrier.
            <br />
            “Consignee” means the person or company to whom the Carrier
            contracts with the Customer to deliver the Consignment.
          </p>
          <p>
            “Consignment” means goods ‐‐ whether sent as a single item or in
            bulk or contained in one parcel, package or container, as the case
            may be, or any number of separate items, parcels, packages or
            containers ‐‐ sent at one time in one load by or for the Customer
            from one address to one address.
            <br />
            “Dangerous Goods” means those substances and articles the carriage
            of which are prohibited by the provisions of the European Agreement
            Concerning the International Carriage of Dangerous Goods by Road
            (ADR) as applied in the United Kingdom, or permitted to be carried
            only under the conditions prescribed therein, as well as all other
            substances and articles of a nature or having characteristics which
            represent a hazard or danger to persons or property, or which
            include any radioactive or explosive material.
            <br />
            “Demurrage” means any cost or expense the Carrier suffers as a
            result of the improper, excessive or unreasonable detention of any
            vehicle, trailer, container or other equipment belonging to or under
            the control of the Carrier.
            <br />
            “Force Majeure Event” shall have the meaning set out in Condition
            10(2)(c)
            <br />
            “In writing” includes, unless otherwise agreed, the transmission of
            information by electronic, optical or similar means of
            communication, including, but not limited to, facsimile, electronic
            mail or electronic data interchange (EDI), provided that the
            information is readily accessible and durable so as to be usable for
            subsequent reference.
          </p>
          <p>
            <strong>2. Parties and Sub‐Contracting</strong>
          </p>
          <p>
            (1) The Customer warrants that he is either the owner of the
            Consignment or is authorised by the owner to accept these Conditions
            on his behalf; and that he is similarly authorised by all those
            having a proprietary or possessory interest in the Consignment, to
            accept these Conditions on their behalf.
            <br />
            (2) The Carrier and any other carrier employed by the Carrier may
            employ the services of any other carrier for the purpose of
            fulfilling the Contract in whole or in part; and the name of every
            other such carrier shall be provided to the Customer upon request.
            The Carrier may at any time assign, mortgage, charge, delegate,
            declare a trust over or deal in any other manner with any or all of
            its rights and obligations under the Contract, to the extent
            permitted by law.
          </p>
          <p>
            (3) The Carrier contracts both for itself and also as agent of and
            trustee for its servants and agents and all other carriers referred
            to in (2) above, and also as agent of and trustee for such other
            carriers' servants and agents; and every reference in these
            Conditions to “the Carrier” shall be deemed to include every other
            such carrier, servant and agent with the intention that they shall
            have the full benefit of the terms of this Contract, and
            collectively and together with the Carrier shall be under no greater
            liability to the Customer or any other party than is the Carrier
            hereunder.
          </p>
          <p>
            (4) Notwithstanding Condition 2(3), the carriage of any Consignment
            by rail, sea, inland waterway or air has been or will be arranged by
            the Carrier solely as agent of the Customer, and any such carriage
            shall be subject to the conditions of the rail, shipping, inland
            waterway or air carrier contracted to carry the Consignment. The
            Carrier shall be under no liability whatsoever, howsoever caused, to
            any person for such carriage: Provided always that where the
            Consignment is carried partly by road and partly by such other means
            of transport any loss, damage or delay shall be deemed to have
            occurred while the Consignment was being carried by road unless the
            contrary is proved by the Carrier.
          </p>
          <p>
            <strong>3. Dangerous Goods</strong>
          </p>
          <p>
            If the Customer does not disclose in writing and in advance that a
            Consignment contains Dangerous Goods, the Carrier shall be entitled
            to rescind the Contract. If the Carrier agrees to accept for
            carriage any Dangerous Goods so disclosed then the Customer must
            arrange for and ensure that the Dangerous Goods are classified,
            packed, marked, labelled and documented in accordance with all
            applicable statutory regulations for the carriage by road of the
            substance declared.
          </p>
          <p>
            <strong>4. Loading and Unloading</strong>
          </p>
          <p>
            (1) Unless otherwise agreed in writing the Customer will be
            responsible for the loading of goods onto the vehicle and will also
            be responsible for the Consignee unloading the goods off the
            vehicle. The Carrier will not be responsible for any loss or damage
            to the goods arising from loading the goods onto or unloading them
            off the vehicle, or from the overloading of the vehicle or from the
            unsafe loading of the vehicle. The Carrier may, at its sole
            discretion, through its servants and agents provide assistance in
            loading or unloading the goods if requested to do so by the Customer
            or the Consignee or the agents of either. The Customer shall
            indemnify the Carrier from and against all and any loss, damage,
            death or injury that may arise whilst the loading or unloading
            operations is taking place whether or not such loss, damage, death
            or injury is attributable to the negligence of the Carrier, its
            agents or servants.
            <br />
            (2) The Customer shall ensure that any cranes, fork lift trucks,
            slings, chains or other equipment used in loading or unloading the
            vehicle are suitable for that purpose and will indemnify the Carrier
            against any and all consequences of failure of or unsuitability of
            such equipment.
            <br />
            (3) The Customer shall ensure that there is adequate access to the
            loading and the unloading points and that the roadways to and from
            the public highway are of suitable material and that unloading will
            take place on good sound hardstanding, where there will be
            sufficient space to load or unload the vehicle in safety.
            <br />
            (4) The Carrier shall not be liable for any loss or damage
            whatsoever, howsoever caused, if the Carrier's personnel are
            instructed by the Customer or the Consignee or their servants or
            agents to provide service to an area which does not comply with
            Condition 4(3) above, whether or not against the recommendations of
            the Carrier or the Carrier's personnel.
          </p>
          <p>
            (5) The Customer shall indemnify the Carrier against all liability
            or loss or damage suffered or incurred (including but not limited to
            damage to the Carrier's vehicle) as a result of the Carrier's
            personnel complying with the instructions of the Customer or the
            Consignee or their servants or agents.
            <br />
            (6) The Customer shall make available to the Carrier upon request
            details of any risk assessments which may have been carried out at
            the collection and/or delivery addresses. The responsibility for
            carrying out such risk assessments shall be that of the Customer and
            not of the Carrier.
          </p>
          <p>
            <strong>5. Obligations of the Customer</strong>
          </p>
          <p>The Customer warrants that:</p>
          <p>
            (1) The Consignment does not and will not: cause pollution of the
            environment or harm to human health; require any official consent or
            licence to handle, possess, deal with or carry; at any time whilst
            in the care or control of the Carrier constitute waste (unless the
            Carrier has been previously advised otherwise); and that the
            Consignment is of a nature that can be legally transported in the
            United Kingdom;
            <br />
            (2) It will comply, and will procure that all of its agents,
            employees and sub‐contractors also comply, with any reasonable
            regulations of the Carrier relating to handling, health and safety,
            and security, of which they are notified or have been notified; and
            <br />
            (3) It will provide the Carrier with such information and materials
            as the Carrier may reasonably require in order to comply with its
            obligations under the Contract, and will ensure that such
            information is complete and accurate in all material respects.
            <br />
            (4) If the Carrier's performance of any of its obligations under the
            Contract is prevented, hindered or delayed by any act or omission of
            the Customer or by any failure by the Customer to perform any
            relevant obligation (Customer Default), then:
            <br />
            (a) without limiting or affecting any other right or remedy
            available to it, the Carrier shall have the right to suspend
            performance of its obligations until the Customer remedies the
            Customer Default, and may rely on the Customer Default to relieve it
            from the performance of any of its obligations in each case to the
            extent the Customer Default prevents, hinders or delays the
            Supplier's performance of any of its obligations;
            <br />
            (b) the Carrier shall not be liable for any costs or losses
            sustained or incurred by the Customer arising directly or indirectly
            from the Carrier's failure to perform or delay in performing any of
            its obligations as set out in this Condition 5(4); and
            <br />
            (c) the Customer shall on written demand reimburse the Carrier for
            any costs or losses sustained or incurred by the Carrier arising
            directly or indirectly from the Customer Default.
          </p>
          <p>
            <strong>6. Signed Receipts</strong>
          </p>
          <p>
            The Carrier shall, if so required, sign a document or electronic
            record prepared by the Customer or its agent acknowledging the
            receipt of the Consignment; but the burden of proving the condition
            of the Consignment and/or its nature, quantity, quality, or weight
            at the time of that receipt shall rest with the Customer.
          </p>
          <p>
            <strong>7. Transit</strong>
          </p>
          <p>
            (1) Unless otherwise agreed expressly between the parties, transit
            shall commence after the Consignment has left the premises from
            where the Consignment is collected.
            <br />
            (2) Transit shall (unless it has terminated earlier) end when the
            Consignment arrives at the proper place of delivery at the
            Consignee's address within the customary cartage hours of the
            district, provided that:
            <br />
            (a) if no safe and adequate access to that address exists, or if no
            safe and adequate unloading facilities exist there, then transit
            shall be deemed to end at the expiry of one clear day after notice
            (by letter, telephone, fax or email or other agreed method of
            communication) of the arrival of the Consignment at the premises has
            been sent to the Consignee or the Customer;
            <br />
            (b) when for any other reason whatsoever a Consignment cannot be
            delivered, or when a Consignment is held by the Carrier on
            instructions ‘to await order' or ‘to be kept till called for' or
            upon any like instructions, and no such order is given within a
            reasonable time, or the Consignment is not called for and removed
            within a reasonable time, then transit shall also be deemed to end
            at the expiry of that reasonable time.
            <br />
            (3) The Consignment shall be at the sole risk of the Customer at all
            times when the Consignment is not in transit.
          </p>
          <p>
            <strong>8. Undelivered or Unclaimed Consignments</strong>
          </p>
          <p>
            Where either of the provisos to Condition 7(2) operate such that
            transit is deemed to have ended, the Carrier may sell the
            Consignment; and payment or tender of the proceeds of sale to the
            Customer, after deduction of all proper charges and expenses in
            relation thereto and of all outstanding charges in relation to the
            carriage and storage of the Consignment, shall discharge the Carrier
            from all liability in respect of such Consignment, its carriage and
            storage:
            <br />
            Provided that:
          </p>
          <p>
            (1) the Carrier shall do what is reasonable to obtain a reasonable
            price for the Consignment; and
            <br />
            (2) the power of sale shall not be exercised where the name and
            address of the Customer or of the Consignee or of the owner of the
            Consignment or of any other person having any proprietary or
            possessory interest in it is known; unless the Carrier shall first
            have done what is reasonable in the circumstances to give notice to
            such persons that the Consignment will be sold unless within the
            time specified in that notice, being a reasonable time in the
            circumstances from the giving of such notice, the Consignment is
            taken away or instructions are given for its disposal.
          </p>
          <p>
            <strong>9. Carrier's Charges</strong>
          </p>
          <p>
            (1) The Carrier's charges shall be payable by the Customer, without
            prejudice to any rights the Carrier may have against the Consignee,
            or any other person, to secure or obtain payment: Provided however
            that when any Consignment is consigned ‘carriage forward' the
            Customer shall not be required to pay such charges unless the
            Consignee shall, within a reasonable period of demand for payment
            having been made of it, have failed to pay the Carrier's charges.
            <br />
            (2) Charges shall be payable when due without deduction or deferment
            on account of any claim, counterclaim or set‐off. If the Customer
            becomes insolvent, or any sums owed by the Customer to the Carrier
            become overdue for payment, all credit terms previously agreed shall
            be cancelled with immediate effect and all invoices and accounts
            issued by the Carrier shall be deemed due for immediate payment and
            all sums owing (whether due or not) shall thereupon become payable.
            The Late Payment of Commercial Debts (Interest) Act 1998, as
            amended, shall apply to all sums due from the Customer.
          </p>
          <p>
            (3) The Carrier shall use reasonable endeavours to obtain a signed
            proof of delivery of the Consignment from the Consignee, unless
            otherwise agreed with the Customer. No payment shall however be
            withheld by the Customer where the Carrier is unable to provide a
            proof of delivery unless notification of non‐delivery is received by
            the Carrier no more than 48 hours after the expected time of
            delivery of the Consignment and the Carrier is subsequently unable
            to evidence proof of delivery.
            <br />
            (4) The Customer shall pay to the Carrier any storage charges
            incurred as a result of it exercising its lien in accordance with
            clause 15 below.
            <br />
            (5) If the Contract is cancelled at any time the Customer shall pay
            the Carrier all costs and expenses which the Carrier has incurred
            prior to such cancellation.
          </p>
          <p>
            <strong>10. Liability for Loss and Damage</strong>
          </p>
          <p>
            (1) The Customer shall be deemed to have elected to accept the terms
            set out in sub‐ clause (2) of this Condition unless, before the
            transit commences, the Customer has agreed in writing that the
            Carrier shall be under no liability for loss of, or mis‐delivery of
            or damage to or in connection with the Consignment, howsoever or
            whensoever caused, and whether or not caused or contributed to,
            directly or indirectly, by any act, omission, neglect, default or
            other wrongdoing on the part of the Carrier, its servants, agents or
            sub‐contractors.
            <br />
            (2) Subject to these Conditions the Carrier shall be liable for:
          </p>
          <p>
            (a) physical loss, mis‐delivery of or damage to living creatures,
            bullion, money, securities, stamps, precious metals or precious
            stones comprised within the Consignment only if:
            <br />
            (i) the Carrier has specifically agreed in writing to carry any such
            items; and
          </p>
          <p>
            (ii) the Customer has agreed in writing to reimburse the Carrier in
            respect of all additional costs which result from the carriage of
            the said items; and
            <br />
            (iii) the loss, mis‐delivery or damage is occasioned during transit
            and is proved to have been caused by the negligence of the Carrier,
            its servants, agents or sub‐contractors;
            <br />
            (b) physical loss, mis‐delivery of or damage to any goods of a type
            not covered by sub‐clause (a) above comprised within the
            Consignment, unless the same has arisen from a Force Majeure Event.
            <br />
            (c) a “Force Majeure Event” shall mean any act(s), event(s),
            circumstance(s) or cause(s) the occurrence of which is beyond the
            reasonable control of the Carrier, including but not limited to:
            <br />
            (i) act of God, riot, civil commotion, strike, lockout, general or
            partial stoppage or restraint of labour from whatever cause, war,
            act of terrorism, seizure or forfeiture under legal process,
            restraint of government;
            <br />
            (ii) error, act, omission, mis‐statement or misrepresentation by the
            Customer or the owner of the Consignment or by any servant or agent
            of either of them;
            <br />
            (iii) inherent wastage in bulk or weight, faulty design, latent
            defect or inherent vice or natural deterioration of the Consignment;
            <br />
            (iv) any special handling requirements in respect of the Consignment
            which have not been notified to the Carrier;
            <br />
            (v) insufficient or improper packaging, labelling or addressing,
            unless the Carrier has contracted to provide this service;
            <br />
            (vi) fire, flood, storm, earthquake, pandemic, or epidemic;
          </p>
          <p>
            (vii) road congestion, road accidents, delays incurred at any
            delivery location or lack of delivery instructions from the
            Customer, vehicle breakdown;
            <br />
            (3) The Carrier shall not in any circumstances be liable for any
            loss or damage arising after transit is deemed to have ended within
            the meaning of Condition 7(2) hereof, whether or not caused or
            contributed to, directly or indirectly, by any act, omission,
            neglect, default or other wrongdoing on the part of the Carrier, its
            servants, agents or sub‐ contractors.
          </p>
          <p>
            <strong>11. Fraud</strong>
          </p>
          <p>
            The Carrier shall in no circumstances be liable in respect of a
            Consignment in relation to which there has been fraud on the part of
            the Customer, the Consignee or the owner of the Consignment, or
            their servants or agents, unless the Carrier or of any servant of
            the Carrier acting in the course of his employment has been
            complicit in that fraud.
          </p>
          <p>
            <strong>12. Limitation of Liability</strong>
          </p>
          <p>
            (1) Except as otherwise provided in these Conditions, the liability
            of the Carrier in respect of claims for physical loss of,
            mis‐delivery of or physical damage to goods comprised within the
            Consignment, howsoever arising, shall in all circumstances be
            limited to the lesser of
            <br />
            (a) the value of the goods actually lost or mis‐delivered, at the
            place they should have been delivered; or the amount by which
            damaged goods have been depreciated in value by reason of that
            damage; or
            <br />
            (b) the cost of replacing the goods actually lost or mis‐delivered
            and/or reconditioning or repairing any damage to the goods; or
            <br />
            (c) a sum calculated at the rate of £1,300 Sterling per tonne on the
            gross weight of the goods actually lost, mis‐delivered or damaged;
            and the value of the goods actually lost, mis‐delivered or damaged
            shall be taken to be their invoice value if they have been sold, and
            shall otherwise be taken to be their replacement cost to the owner
            at the commencement of the transit, and in all cases shall be taken
            to include any Customs and Excise duties or taxes paid or payable in
            respect of those goods when lost, mis‐delivered or damaged:
            <br />
            Provided that:
          </p>
          <p>
            (i) in the case of loss, mis‐delivery of or damage to a part of the
            Consignment, the weight to be taken into consideration in
            determining the amount to which the Carrier's liability is limited
            shall be only the gross weight of that part, regardless of whether
            the loss, mis‐delivery or damage affects the value of other parts of
            the Consignment;
            <br />
            (ii) nothing in this Condition shall limit the liability of the
            Carrier to less than the sum of £10;
            <br />
            (iii) the Carrier shall be entitled to proof of the weight and value
            of the whole of the Consignment and of any part thereof lost,
            mis‐delivered or damaged;
            <br />
            (iv) the Customer shall be entitled to give to the Carrier notice in
            writing, to be delivered at least seven days prior to commencement
            of transit, requesting that the £1,300 per tonne limit referred to
            in Condition 12(1)(c) above be increased (but not so as to exceed
            the value of the Consignment) and in the event of such notice being
            given the Customer shall be required to agree with the Carrier an
            increase in the carriage charges, but if no such agreement can be
            reached the aforementioned
            <br />
            £1,300 per tonne limit shall continue to apply.
          </p>
          <p>
            (2) The liability of the Carrier in respect of claims for any other
            type of loss, liability or damage whatsoever and howsoever arising
            in connection with the Consignment shall not exceed the amount of
            the carriage charges in respect of the Consignment or the amount of
            the claimant's proved loss, whichever is the less, unless:
            <br />
            (a) at the time of entering into the Contract with the Carrier, the
            Customer declares to the Carrier a special interest in the avoidance
            of physical loss, mis‐delivery or damage to the Consignment, and/or
            a special interest in delivery within a specified period,
            undertaking to pay such surcharge, referable to the declared value
            of that interest or those interests, as may be agreed with the
            Carrier, and
            <br />
            (b) at least 7 days prior to the commencement of transit the
            Customer has delivered to the Carrier confirmation in writing of the
            declared value of any special interest and of any agreed time limit,
            and of its agreement to pay the specified surcharge which it has
            agreed with the Carrier.
            <br />
            (3) The Carrier shall not be in breach of the Contract nor liable
            for any delay in performing, or failure to perform, any of its
            obligations under the Contract if such delay or failure results from
            a Force Majeure Event.
            <br />
            (4) The following types of loss or damage are wholly excluded, and
            will not under any circumstances be the subject of compensation by
            the Carrier:
            <br />
            (a) loss of profits;
          </p>
          <p>(b) loss of sales or business;</p>
          <p>(c) loss of agreements or contracts;</p>
          <p>(d) loss of anticipated savings;</p>
          <p>
            (e) loss of use of, or corruption of, software, data or information;
          </p>
          <p>(f) loss of or damage to goodwill;</p>
          <p>(g) indirect or consequential loss;</p>
          <p>
            (h) any fine imposed on the Customer by the Consignee or its
            customer.
          </p>
          <p>
            <strong>13. Indemnity to the Carrier</strong>
          </p>
          <p>The Customer shall indemnify the Carrier against:</p>
          <p>
            (1) all losses, liabilities and costs incurred by the Carrier
            (including but not limited to those incurred in connection with loss
            of or damage to the carrying vehicle or to other goods carried) as a
            result of any breach of these Conditions by the Customer or any
            party on whose behalf it has contracted, or by reason of any error,
            omission, mis‐statement or misrepresentation by the Customer or
            owner of the Consignment or by any servant or agent of either of
            them, or by reason of insufficient or improper packing, labelling or
            addressing of the Consignment, or by reason of fraud on the part of
            the Customer, the Consignee or the owner of the Consignment, or
            their servants or agents (as referred to in Condition 11);
            <br />
            (2) all losses, liabilities and costs arising from claims and
            demands by whomsoever made and howsoever arising (including, for the
            avoidance of doubt, claims alleging negligence or conversion, or by
            H.M. Revenue and Customs in respect of dutiable goods, or arising
            out of the carriage of Dangerous Goods) in respect of any loss of or
            damage to, or in connection with, the Consignment in an amount
            exceeding the liability of the Carrier under these Conditions in
            respect of that loss or damage, whether or not that loss or damage
            was caused or contributed to, directly or indirectly, by any act,
            omission, neglect, default or other wrongdoing on the part of the
            Carrier, its servants, agents or sub‐contractors.
          </p>
          <p>
            <strong>14. Time Limits for Claims</strong>
          </p>
          <p>(1) The Carrier shall not be liable for:</p>
          <p>
            (a) physical loss of, mis‐ or non‐delivery of, or physical damage to
            goods comprised within the Consignment unless advised thereof in
            writing within seven days after the termination of transit or the
            date on which the transit should have terminated;
            <br />
            (b) any other type of loss unless advised thereof in writing within
            twenty‐eight days after the termination of transit or the date on
            which the transit should have terminated.
            <br />
            Provided that if the Customer proves that,
          </p>
          <p>
            (i) it was not reasonably possible for the Customer to advise the
            Carrier or make a claim in writing within the time limit applicable,
            and
            <br />
            (ii) such advice or claim was given or made within a reasonable time
            after the time at which it did become reasonably possible for the
            Customer to advise the Carrier or make a claim in writing,
            <br />
            the Carrier shall not have the benefit of the exclusion of liability
            afforded by this Condition.
            <br />
            (2) The Carrier shall in any event be discharged from all liability
            whatsoever and howsoever arising in respect of the Consignment
            unless legal proceedings are issued and notice in writing thereof
            given to the Carrier within one year of the date when transit
            commenced.
            <br />
            (3) In the computation of time where any period provided by these
            Conditions is seven days or less, Saturdays, Sundays and all
            statutory public holidays shall be excluded.
            <br />
            15. Lien
          </p>
          <p>(1) The Carrier shall have:</p>
          <p>
            (a) a particular lien on the Consignment for all charges due to the
            Carrier for the carriage, storage and/or warehousing of the
            Consignment and for all other proper charges or expenses incurred in
            connection with the carriage of the Consignment, and
            <br />
            (b) a general lien on the Consignment for any sums overdue and
            unpaid by the Customer, by the owner of the Consignment or by any
            other person having any proprietary or possessory interest in it, by
            the Consignee, or by any agent of these persons, on any invoice,
            account or contract whatsoever.
            <br />
            If the Carrier exercises a lien, but appropriate payment is not made
            within 14 days after notice that the payment is due has been given
            in accordance with Condition 8(2) above, the Carrier may sell the
            Consignment, or any part thereof, as agent for its owner and for
            those having a proprietary or possessory interest in it, and shall
            apply the proceeds towards any sums unpaid and towards the expenses
            of the retention, storage, insurance and sale of the Consignment and
            shall, upon accounting to the Customer for any balance remaining, be
            discharged from all liability whatsoever in respect of the
            Consignment.
            <br />
            (2) The Carrier may exercise its lien on its own behalf or as agent
            for any assignee of its invoices at any time and at any place in its
            sole discretion, whether or not the contractual carriage has been
            completed, and these Conditions shall continue to apply during the
            period of exercise of such lien.
            <br />
            (3) If the Consignment is not solely the property of the Customer,
            the Customer warrants that it has the authority of all those having
            a proprietary or possessory interest in the Consignment to grant to
            the Carrier liens as set out in Condition 15(1) above, and the
            Customer shall indemnify the Carrier for all claims and demands the
            Carrier may receive asserting that the Customer did not have that
            authority.
            <br />
            16. Unreasonable Detention
          </p>
          <p>
            The Customer shall be liable to pay Demurrage, without prejudice to
            any rights that the Carrier may have against any other person in
            respect of any improper, excessive or unreasonable detention of any
            vehicle, trailer, container or other equipment belonging to or under
            the control of the Carrier.
            <br />
            17. Confidentiality
          </p>
          <p>
            (1) Each party undertakes that it shall not at any time disclose to
            any person any confidential information concerning the business,
            affairs, customers, clients or suppliers of the other party, except
            as permitted by these Conditions.
            <br />
            (2) Each party may disclose the other party's confidential
            information:
          </p>
          <p>
            (a) to its employees, officers, representatives, sub‐contractors or
            advisers who need to know such information for the purposes of
            carrying out the party's legal obligations; and
            <br />
            (b) as may be required by law, a court of competent jurisdiction or
            any governmental or regulatory authority.
            <br />
            18. Law and Jurisdiction
          </p>
          <p>
            Unless otherwise agreed in writing, the Contract and any dispute
            arising under it or in connection with it shall be governed by
            English law and each party irrevocably agrees that such dispute
            shall be subject to the exclusive jurisdiction of the English
            courts.
          </p>
          <p>
            <strong>
              THESE CONDITIONS MAY ONLY BE USED BY MEMBERS OF THE ROAD HAULAGE
              ASSOCIATION
            </strong>
          </p>
          <p>
            <a href="http://www.hastingsfreight.co.uk/" className="modallink">
              I <strong>DO NOT</strong> accept these Terms and Conditions and
              wish to be taken back to the HastingsFreight Home page
            </a>
          </p>
        </div>
        <div className="buttondiv">
          <button type="button" onClick={handleClose} className="">
            I accept
          </button>
        </div>
      </section>
    </div>
  );
};

export default ModalPopUp;
