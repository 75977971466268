import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceInternationalContent extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                <img
                  src={
                    publicUrl + "assets/img/service/international_850_650a.jpg"
                  }
                  alt="Image"
                />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    What we do
                  </h6>
                  <i class="bi bi-alarm"></i>
                  <h1 className="section-title">International Freight</h1>
                  <p>
                    At Hastings freight we pride ourselves on international
                    solutions, not only do we work with one of Europe's biggest
                    Express Palletised Distribution Networks (Palletways) we
                    also have a wide range of bespoke solutions for your full
                    and part loads anywhere in the world. Our experienced team
                    will guide you throughout the journey and custom
                    requirements. Independent tracking on network freight, gives
                    you access to all the information and documents you need in
                    post BREXIT trading.
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link
                    to="/contact"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceInternationalContent;
