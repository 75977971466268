import React from "react";
import Navbar from "./global-components/navbar-v3";
import PageHeader from "./global-components/page-header";
import ServiceInternationalContent from "./section-components/service-international-content";
import ServiceInternationalBoxes from "./section-components/service-international-boxes";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const ServiceInternationalPage = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="International Freight" subheader="Services" />
      <ServiceInternationalContent />
      <ServiceInternationalBoxes />
      {/* <BlogSlider sectionClass="pt-120" /> */}
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default ServiceInternationalPage;
