import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceUKContent extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                <img
                  src={publicUrl + "assets/img/service/uk_freight.jpg"}
                  alt="Image"
                />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    What we do
                  </h6>
                  <h1 className="section-title">UK Freight</h1>
                  <p>
                    Leading the way with state-of-the-art technology, We offer a
                    wide range of services for your transport needs in the UK
                    &amp; ROI. From Next Day B2C services, to deliveries to
                    Amazon distribution centres, our experienced driver's are
                    the final link in your distribution chain. We cover all
                    areas of the UK through dedicated vehicles and by partnering
                    with 2 major Pallet Networks. We know communication is key,
                    our customer service team is always on hand to answer any
                    query.
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link
                    to="/contact"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceUKContent;
