import React, { Component } from "react";
import axios from "axios";
import { useState } from "react";

function ContactForm() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let apiurl = "https://hastings.mailshipper.co.uk";

  const [post, setPost] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
    submitmessage: "",
    formvalidcss: "",
  });

  const handleInput = (event) => {
    setPost({ ...post, [event.target.name]: event.target.value });
  };

  const handleChange = (selectedOption) => {
    var e = document.getElementById("ddservice");
    setPost({ ...post, ["service"]: e.options[e.selectedIndex].value });
  };

  function validateForm() {
    let formIsValid = true;

    if (post.name == "") {
      formIsValid = false;
    }
    if (post.email == "") {
      formIsValid = false;
    }
    if (post.phone == "") {
      formIsValid = false;
    }
    if (post.service == "") {
      formIsValid = false;
    }
    if (post.message == "") {
      formIsValid = false;
    }
    return formIsValid;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      axios
        .post(apiurl + "/sendenquirymail", {
          post,
        })
        .then((response) =>
          setPost({
            name: "",
            email: "",
            phone: "",
            service: "",
            message: "",
            submitMessage: "Success! We will be in touch with your shortly",
            formvalidcss: "",
          })
        )
        .catch((err) => console.log(err));
    } else {
      setPost({
        ...post,
        submitMessage:
          "You must complete all mandatory fields before submitting",
        formvalidcss: "warning",
      });
    }
  }

  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Ask a question</h4>
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        onChange={handleInput}
                        value={post.name}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        onChange={handleInput}
                        value={post.email}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item">
                      <select
                        id="ddservice"
                        name="service"
                        onClick={handleChange}
                        value={post.service}
                      >
                        <option value="" defaultValue={true}>
                          Select Service Type
                        </option>
                        <option value="UK Frieght">UK Freight</option>
                        <option value="European Freight">
                          European Freight
                        </option>
                        <option value="Global Freight">Global Freight</option>
                        <option value="Online Tools">Online Tools</option>
                        <option value="Careers">Careers</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Enter phone number"
                        onChange={handleInput}
                        value={post.phone}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="message"
                    placeholder="Enter message"
                    defaultValue={""}
                    onChange={handleInput}
                    value={post.message}
                  />
                </div>
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                <label
                  name="submitresponse"
                  className={"submitMessage " + post.formvalidcss}
                >
                  {post.submitMessage}
                </label>
                <p className="form-messege mb-0 mt-20" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
