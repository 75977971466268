import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class HomeAboutContent extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120 pb-90 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/hastings_truck.jpg"}
                  alt="About Us Image"
                />
                {/* <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                  <img
                    src={publicUrl + "assets/img/others/DSC_1890a.jpg"}
                    alt="video popup bg image"
                  />
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color-2">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    UK &amp; International transport solutions
                  </h1>
                  <p>
                    We provide an exceptional service by understanding logistics
                    is not just about transport. Working with Hastings Freight
                    means the successful integration of systems, processes and
                    people. Understanding that we form part of your reputation,
                    drives us to use over 25 years' of experience to provide
                    outstanding transport solutions.
                  </p>
                  {/* <i className="fa-regular fa-calendar-check" /> */}
                </div>
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-secure" />
                    Next day delivery
                  </li>
                  <li>
                    <i className="flaticon-maps-and-location" />
                    Online Tracking
                  </li>
                  <li>
                    <i className="flaticon-package" />
                    ETA Notifications
                  </li>
                  <li>
                    <i className="fa-regular fa-calendar-check" />
                    Evening Deliveries
                  </li>
                  <li>
                    <i className="flaticon-buy-home" />
                    Tail Lift Deliveries
                  </li>
                  <li>
                    <i className="flaticon-building" />
                    Storage options
                  </li>
                  <li>
                    <i className="flaticon-secure-shield" />
                    System integration
                  </li>
                  <li>
                    <i className="flaticon-call-center-agent" />
                    Tailored support
                  </li>
                </ul>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    With Hastings Freight you can be assured your delivery, is
                    our priority.
                  </p>
                </div>
                <div className="btn-wrapper animated go-top">
                  <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                    OUR SERVICES
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeAboutContent;
