import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceAccountContent extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                <img
                  src={publicUrl + "assets/img/service/accounts.jpg"}
                  alt="Image"
                />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    What we do
                  </h6>
                  <h1 className="section-title">Online Account Management</h1>
                  <p>
                    By choosing Hastings Freight as your transport partner, you
                    will have access to a dedicated portal, specific to your
                    chosen network streamlining your experience, through simple
                    online ordering or integration with your systems. We
                    constantly review to keep us one step ahead of the
                    competition, to provide extra reassurance that your pallets
                    will reach their desired destination safely and securely.
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link
                    to="/contact"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceAccountContent;
