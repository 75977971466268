import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceUKBoxes extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  What we offer
                </h6>
                <h1 className="section-title">Why choose Hastings Freight?</h1>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={
                        publicUrl + "assets/img/service/boxes/UK_freight.jpg"
                      }
                      alt="Hastings truck on Uk road"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Choice of 24/48 hour, specific date delivery</h3>
                  <p>
                    Including timed, Saturday and Evening deliveries to suit
                    your customer's needs
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tail_lift.jpg"}
                      alt="Hastings truck using tail lift"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Tail Lift and home delivery specialists</h3>
                  <p>
                    from trees to coal we deliver from your business to consumer
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/booking.jpg"}
                      alt="Hastings office worker on the phone"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Don't miss a delivery with our book in service</h3>
                  <p>let your customer pick a date that's suitable to them</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tracking.jpg"}
                      alt="Finger pointing to on screen tracking map"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>24hr online account access with tracking</h3>
                  <p>with Palletways manage your account, your way</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/eta_app.jpg"}
                      alt="Smart phone showing tracking app"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>ETA notifications direct to your customer</h3>
                  <p>available via text or email through Palletways</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/building.jpg"}
                      alt="Hastings Freight office exterior"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    Logistics Made <br /> Easy
                  </h3>
                  <p>
                    Meet Our Team{" "}
                    <Link to="/Team" className="highlightLink">
                      Here
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceUKBoxes;
