import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FeaturesV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    let customClass = this.props.customClass ? this.props.customClass : "";

    return (
      <div className={customClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Services
                </h6>
                <h1 className="section-title">What we offer</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                <div className="ltn__feature-icon">
                  <img
                    src={publicUrl + "assets/img/icons/icon-img/uk.jpg"}
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-uk">
                      UK &amp; ROI <br /> Freight
                    </Link>
                  </h3>
                  <p>
                    Along with our partners, we can offer Next-Day, timed and
                    special deliveries all across the UK and Channel Islands
                  </p>
                  <Link className="ltn__service-btn" to="/service-uk">
                    Read more <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      publicUrl + "assets/img/icons/icon-img/international.jpg"
                    }
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-international">
                      International Freight
                    </Link>
                  </h3>
                  <p>
                    With hubs all across Europe and Worldwide. International
                    delivery is no problem when using Hatings Freight Ltd
                  </p>
                  <Link
                    className="ltn__service-btn"
                    to="/service-international"
                  >
                    Read more <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={publicUrl + "assets/img/icons/icon-img/pallet.jpg"}
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-transport">Bespoke Solutions</Link>
                  </h3>
                  <p>
                    Our palletised delivery services offer you the most
                    economical way to delivery your Uk or Euro pallet
                  </p>
                  <Link className="ltn__service-btn" to="/service-transport">
                    Read more <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      publicUrl +
                      "assets/img/icons/icon-img/account_management.jpg"
                    }
                    alt="#"
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-accounts">
                      Online Account Management
                    </Link>
                  </h3>
                  <p>
                    A dedicated portal ensures visiblity for all of your
                    consignments, instant tracking and 24 hour ETAs
                  </p>
                  <Link className="ltn__service-btn" to="/service-accounts">
                    Read more <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesV1;
