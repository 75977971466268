import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Testimonial extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div
        className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Testimonials
                </h6>
                <h1 className="section-title">Customer Reviews</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    “Thanks so much for the flexibiilty with my delivery date.
                    Driver was a nice man too. Really happy with the service.
                    Cheers!”
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/img/testimonial/1.jpg"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Sean Lovell</h5>
                      <label>Customer</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    “I can't thank you and your team enough for always going
                    that extra mile, you go above and beyond expectations.
                    You're an absolute pleasure to work with”
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/img/testimonial/1.jpg"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Superior Wellness</h5>
                      <label>Chesterfield based company</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    "Hastings delivered a shower screen to me today and I have
                    to say the professionalism from start to finish was superb.
                    Their office phoned yesterday to ask which day I'd prefer to
                    receive it, and today the driver phoned me with a time
                    window of when I could expect him to arrive with it. He even
                    took away the pallet for me. Superb service!"
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/img/testimonial/1.jpg"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Bernie Hurst</h5>
                      <label>Customer</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
