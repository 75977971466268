import React from "react";
import Navbar from "./global-components/navbar-v3";
import BannerHome from "./section-components/banner-home";
import SearchForm from "./section-components/search-form";
import HomeAboutContent from "./section-components/home-about";
import Counter from "./section-components/counter-v1";
import Featuresv1 from "./section-components/features-v1";
import Testimonial from "./section-components/testimonial-v1";
import BlogSlider from "./blog-components/blog-slider";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const Home_V1 = () => {
  return (
    <div>
      <Navbar headerStyle="ltn__header-transparent" />
      <BannerHome />
      <SearchForm />
      <HomeAboutContent />
      <Counter />
      <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
      <Testimonial />
      <BlogSlider customClass="section-subtitle-2" />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Home_V1;
