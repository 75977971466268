import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useState, useEffect } from "react";
import axios from "axios";
import ModalPopUp from "./modalpopup";

function GetStartedForm() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let apiurl = "https://hastings.mailshipper.co.uk";

  const [post, setPost] = useState({
    companyname: "",
    yourname: "",
    position: "",
    email: "",
    phone: "",
    companyreg: "",
    submitmessage: "",
    showpopup: false,
    hasaccepted: false,
  });

  const showModal = () => {
    setPost({ ...post, ["showpopup"]: true });
  };

  const hideModal = () => {
    setPost({ ...post, ["showpopup"]: false, ["hasaccepted"]: true });
  };

  const handleInput = (event) => {
    setPost({ ...post, [event.target.name]: event.target.value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (!post.hasaccepted) {
      setPost({
        ...post,
        ["submitmessage"]: "You must accept the terms and conditions",
      });
    } else {
      axios
        .post(apiurl + "/sendgetstartedemail", { post })
        .then((response) =>
          setPost({
            companyname: "",
            yourname: "",
            position: "",
            email: "",
            phone: "",
            companyreg: "",
            hasaccepted: false,
            showpopup: false,
            submitmessage: "Success! We will be in touch with you shortly",
          })
        )
        .catch((err) => console.log(err));
    }
  }

  return (
    <div className="ltn__appointment-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__appointment-inner">
              <form onSubmit={handleSubmit}>
                <h2>Getting started with Hastings Freight</h2>
                <p>
                  <small>
                    These fields are mandatory: Company name, Your name, Your
                    position, Your email address and Your phone number
                  </small>
                </p>
                <h6>Your details</h6>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="companyname"
                        placeholder="*Your company name (mandatory)"
                        value={post.companyname}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="yourname"
                        placeholder="*Your name (mandatory)"
                        value={post.yourname}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="position"
                        placeholder="*Your position (mandatory)"
                        value={post.position}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                <h6>Contact details</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="email"
                        placeholder="* Your email address (mandatory)"
                        value={post.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        placeholder="* Your phone number (mandatory)"
                        value={post.phone}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="companyreg"
                        placeholder="Company Registration Number"
                        value={post.companyreg}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                <h6>Terms &amp; Conditions Acceptance</h6>
                <p>
                  Checking the box below will display our Terms &amp; Conditions
                  which must be accepted before submitting this form. Upon
                  checking the box you are requesting to open an account under
                  agreed terms subject to credit check.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    {/* <label className="checkbox-item">
                      <strong>
                        I accept as an authorised representative of my company
                      </strong>
                      <Popup
                        trigger={<input type="checkbox" />}
                        position="center center"
                        modal="true"
                      >
                        {(close) => (
                          <div
                            className="modafl"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                          >
                            <button className="close" onClick={close}>
                              &times;
                            </button>
                            <div className="header"> Modal Title </div>
                            <div className="content">
                              {" "}
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Atque, a nostrum. Dolorem, repellat quidem
                              ut, minima sint vel eveniet quibusdam voluptates
                              delectus doloremque, explicabo tempore dicta
                              adipisci fugit amet dignissimos?
                              <br />
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Consequatur sit commodi beatae
                              optio voluptatum sed eius cumque, delectus saepe
                              repudiandae explicabo nemo nam libero ad,
                              doloribus, voluptas rem alias. Vitae?
                            </div>
                            <div className="actions">
                              <button
                                className="button"
                                onClick={() => {
                                  console.log("modal closed ");
                                  close();
                                }}
                              >
                                close modal
                              </button>
                            </div>
                          </div>
                        )}
                      </Popup>

                      <span className="checkmark" />
                    </label> */}
                    <input
                      type="checkbox"
                      value={post.hasaccepted}
                      onClick={showModal}
                    ></input>
                    <strong>
                      {" "}
                      I accept as an authorised representative of my company
                    </strong>
                  </div>
                </div>

                <div className="alert alert-warning d-none" role="alert">
                  Please note that the date and time you requested may not be
                  available. We will contact you to confirm your actual
                  appointment details.
                </div>
                <div className="btn-wrapper text-center mt-30">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    Submit form
                  </button>
                  <br />
                  <br />
                  <label name="submitresponse" className="ltn__lumo-color">
                    {post.submitmessage}
                  </label>
                </div>
                <ModalPopUp
                  show={post.showpopup}
                  handleClose={hideModal}
                ></ModalPopUp>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStartedForm;
