import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";

class NavbarV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";
    return (
      <div>
        <header
          className={`ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ${
            this.props.headerStyle || " ltn__nav-heading-color"
          } gradient-color-4---`}
        >
          <div className="ltn__header-top-area section-bg-6 top-area-color-white---">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li>
                        <a>
                          <i className="icon-mail" /> info@hastingsfreight.co.uk
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="icon-phone-call" /> 0845 673 2030
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://portal.palletways.com/login_v2.php"
                          target="_blank"
                        >
                          <i className="icon-menu" /> Customer Login
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="top-bar-right text-end">
                    <div className="ltn__top-bar-menu">
                      <ul>
                        <li>
                          {/* ltn__language-menu */}
                          <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                            <ul></ul>
                          </div>
                        </li>
                        <li>
                          <Social />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="site-logo-wrap">
                    <div className="site-logo go-top">
                      <Link to="/">
                        <img
                          src={publicUrl + "assets/img/logo.png"}
                          alt="Logo"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col header-menu-column menu-color-white">
                  <div className="header-menu d-none d-xl-block go-top">
                    <nav>
                      <div className="ltn__main-menu">
                        <ul>
                          <li>
                            <Link to="/home">Home</Link>
                          </li>
                          <li className="menu-icon">
                            <Link to="/about">About Us</Link>
                            <ul>
                              <li>
                                <Link to="/about">Our mission</Link>
                              </li>
                              <li>
                                <Link to="/your-career">Our opportunities</Link>
                              </li>
                              <li>
                                <Link to="/team">Our team</Link>
                              </li>
                              <li>
                                <Link to="/faq">FAQs</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="/service-uk">Services</Link>
                            <ul>
                              <li>
                                <Link to="/service-uk">
                                  UK &amp; ROI Freight
                                </Link>
                              </li>
                              <li>
                                <Link to="/service-international">
                                  International Freight
                                </Link>
                              </li>
                              <li>
                                <Link to="/service-transport">
                                  Bespoke Solutions
                                </Link>
                              </li>
                              <li>
                                <Link to="/service-accounts">
                                  Online Account Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="/news">News</Link>
                            <ul>
                              <li>
                                <Link to="/news">What's going on @ HF</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="/careers">Careers</Link>
                          </li>
                          <li className="special-link">
                            <Link to="/contact">Contact Us</Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col--- ltn__header-options ltn__header-options-2 ">
                  {/* Mobile Menu Button */}
                  <div className="mobile-menu-toggle d-xl-none">
                    <a
                      href="#ltn__utilize-mobile-menu"
                      className="ltn__utilize-toggle"
                    >
                      <svg viewBox="0 0 800 600">
                        <path
                          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                          id="top"
                        />
                        <path d="M300,320 L540,320" id="middle" />
                        <path
                          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                          id="bottom"
                          transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <div className="site-logo">
                <Link to="/">
                  <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                </Link>
              </div>
              <button className="ltn__utilize-close">×</button>
            </div>
            <div className="ltn__utilize-menu">
              <ul>
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">Our mission</Link>
                    </li>
                    <li>
                      <Link to="/your-career">Our opportunities</Link>
                    </li>
                    <li>
                      <Link to="/team">Our team</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQs</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/service-uk">Services</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/service-uk">UK &amp; ROI Freight</Link>
                    </li>
                    <li>
                      <Link to="/service-international">
                        International Freight
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-transport">Bespoke Solutions</Link>
                    </li>
                    <li>
                      <Link to="/service-accounts">
                        Online Account Management
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/news">News</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/news">What's going on @ HF</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="careers">Careers</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            {/* <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
              <ul>
                <li>
                  <Link to="/my-account" title="My Account">
                    <span className="utilize-btn-icon">
                      <i className="far fa-user" />
                    </span>
                    My Account
                  </Link>
                </li>
                <li>
                  <Link to="/wishlist" title="Wishlist">
                    <span className="utilize-btn-icon">
                      <i className="far fa-heart" />
                      <sup>3</sup>
                    </span>
                    Wishlist
                  </Link>
                </li>
                <li>
                  <Link to="/cart" title="Shoping Cart">
                    <span className="utilize-btn-icon">
                      <i className="fas fa-shopping-cart" />
                      <sup>5</sup>
                    </span>
                    Shoping Cart
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default NavbarV3;
