import React, { Component } from "react";
import { Link } from "react-router-dom";
import Comments from "./comments";
import Sidebar from "./sidebar";
import axios from "axios";
import { useParams, withRouter } from "react-router-dom";

class BlogDetails extends Component {
  constructor(props) {
    super(props);
  }

  state = { news: null, isLoaded: false };

  componentDidMount() {
    let apiurl = "https://hastingsapi.wangbodev.co.uk";
    axios
      .get(apiurl + "/news/" + this.props.id)
      .then((res) => {
        this.setState({
          news: res.data,
          isLoaded: true,
        });
      })
      .catch((err) => console.log("Catch" + err));
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    const { news, isLoaded } = this.state;

    if (isLoaded) {
      return (
        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="ltn__blog-details-wrap">
                  <div className="ltn__page-details-inner ltn__blog-details-inner">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-category">
                          <Link to="/blog-details"></Link>
                        </li>
                      </ul>
                    </div>
                    <h2 className="ltn__blog-title">{news.title}</h2>
                    <div className="ltn__blog-meta" key="1">
                      <ul>
                        <li className="ltn__blog-author go-top">
                          <i className="far fa-user" />
                          {news.author}
                        </li>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          {news.printDate}
                        </li>
                      </ul>
                    </div>
                    <img
                      src={"/assets/img/news/" + news.image}
                      alt="#"
                      width="640px"
                      height="420px"
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: news.content,
                      }}
                    />
                  </div>

                  {/* <div className="ltn__blog-tags-social-media mt-80 row">
                    <div className="ltn__tagcloud-widget col-lg-8 go-top">
                      <h4>Related Tags</h4>
                      <ul>
                        {newsitem._embedded["wp:term"][1].map((tag) => (
                          <li>
                            <Link
                              to="#"
                              dangerouslySetInnerHTML={{ __html: tag.name }}
                            ></Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <hr /> */}

                  {/* <div className="related-post-area mb-50">
                    <h4 className="title-2">Related Posts</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="ltn__blog-item ltn__blog-item-6">
                          <div className="ltn__blog-img">
                            <Link to="/blog-details">
                              <img
                                src={publicUrl + "assets/img/blog/2.jpg"}
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__blog-brief">
                            <div className="ltn__blog-meta">
                              <ul>
                                <li className="ltn__blog-date ltn__secondary-color">
                                  <i className="far fa-calendar-alt" />
                                  June 22, 2020
                                </li>
                              </ul>
                            </div>
                            <h3 className="ltn__blog-title">
                              <Link to="/blog-details">
                                The class of 2020 - new starters
                              </Link>
                            </h3>
                            <p>Our young persons scheme kicked off our 2020</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="ltn__blog-item ltn__blog-item-6">
                          <div className="ltn__blog-img">
                            <Link to="/blog-details">
                              <img
                                src={publicUrl + "assets/img/blog/3.jpg"}
                                alt="Image"
                              />
                            </Link>
                          </div>
                          <div className="ltn__blog-brief">
                            <div className="ltn__blog-meta">
                              <ul>
                                <li className="ltn__blog-date ltn__secondary-color">
                                  <i className="far fa-calendar-alt" />
                                  June 22, 2020
                                </li>
                              </ul>
                            </div>
                            <h3 className="ltn__blog-title">
                              <Link to="/blog-details">
                                Hastings expands UK mapping options
                              </Link>
                            </h3>
                            <p>
                              This year sees Hastings Frieght expand your maps
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <Sidebar />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
        <div className="container">
          <div className="row">
            <center>
              <img
                src={publicUrl + "assets/img/icons/icon-img/loading.gif"}
                alt="#"
              ></img>
              <h3>Loading...</h3>;
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogDetails;
