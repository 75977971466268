import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class CounterV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__counterup-area section-bg-1 pt-120 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <div className="counter-icon">
                  <i className="flaticon-financial" />
                </div>
                <h1>
                  <span className="counter">1000</span>
                  {/* <span className="counterUp-letter">mil</span> */}
                  <span className="counterUp-icon">+</span>{" "}
                </h1>
                <h6>Pallets handled every day</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <div className="counter-icon">
                  <i className="flaticon-building" />
                </div>
                <h1>
                  <span className="counter">300</span>
                  <span className="counterUp-icon">+</span>{" "}
                </h1>
                <h6>Businesses served</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <div className="counter-icon">
                  <i className="flaticon-maps-and-location" />
                </div>
                <h1>
                  <span className="counter">70</span>
                  <span className="counterUp-icon">+</span>{" "}
                </h1>
                <h6>Countries accessible</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <div className="counter-icon">
                  <i className="flaticon-quotation" />
                </div>
                <h1>
                  <span className="counter">25</span>
                  <span className="counterUp-letter">+</span>
                </h1>
                <h6>Years of industry experience</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CounterV1;
