import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceTransportContent extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                <img
                  src={publicUrl + "assets/img/service/transport_warehouse.jpg"}
                  alt="Image"
                />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    What we do
                  </h6>
                  <h1 className="section-title">
                    Transport &amp; Storage Solutions
                  </h1>
                  <p>
                    Providing Full and Part solutions across the UK and
                    Internationally, at Hastings Freight we pride ourselves on
                    being able to service anywhere. Our dedicated team are
                    always on hand to provide competitive pricing. We also offer
                    short or long term bulk or single pallet storage. Our 60,000
                    sq foot made up of 2 interlocking warehouses with segregated
                    areas give our customers the option to store pallets on
                    site. Our site is monitored 24 hours a day by CCTV providing
                    extra security. We can also offer bespoke pricing for off
                    site storage.
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link
                    to="/contact"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceTransportContent;
