import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FaqV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__faq-area mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="ltn__faq-inner ltn__faq-inner-2">
                <h4>PALLET PREPARATION</h4>
                <div id="accordion_2">
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-1"
                      aria-expanded="false"
                    >
                      HOW DO I SEND A PALLET?
                    </h6>
                    <div
                      id="faq-item-2-1"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          If you would like to book a one off shipment, just
                          complete the online form and you can book your pallet
                          in for collection in a few easy steps.
                        </p>
                        <p>
                          Alternatively, if you're looking for a reliable
                          transport partner, click on{" "}
                          <Link to="/get-started" className="highlightLink">
                            'Open an account'
                          </Link>{" "}
                          and we'll contact you to discuss your needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-2"
                      aria-expanded="false"
                    >
                      CAN I SEND INTERNATIONALLY WITH HASTINGS FREIGHT?
                    </h6>
                    <div
                      id="faq-item-2-2"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Yes! Contact us at info@hastingsfreight.co.uk to
                          discuss your needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-3"
                      aria-expanded="false"
                    >
                      HOW SHOULD I WRAP MY PALLET?
                    </h6>
                    <div
                      id="faq-item-2-3"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <div className="ltn__video-img alignleft">
                          <img
                            src={publicUrl + "assets/img/bg/17.jpg"}
                            alt="video popup bg image"
                          />
                          <a
                            className="ltn__video-icon-2 ltn__video-icon-2-small ltn__video-icon-2-border----"
                            href="https://www.youtube.com/embed/sPE7DjSxeQM?si=u4LR2iTdKqEPf9w4"
                            data-rel="lightcase:myCollection"
                          >
                            <i className="fa fa-play" />
                          </a>
                        </div>
                        <p>
                          All goods should be secured to a pallet using pallet
                          wrapping or appropriate strapping before the driver
                          arrives.
                        </p>
                        <p>
                          In addition to pallet wrapping (like a strong cling
                          film) it is essential that the goods are already well
                          packaged and protected.
                        </p>
                        <p>
                          Goods must remain within the confines of the pallet.
                        </p>
                        <p>
                          For more information on how to prepare you goods for
                          transit, see our video here.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-4"
                      aria-expanded="false"
                    >
                      WHAT ARE THE SIZE, WEIGHT AND HEIGHT RESTRICTIONS OF A
                      PALLET?
                    </h6>
                    <div
                      id="faq-item-2-4"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          To offer our customers flexibility and help reduce
                          costs, we offer a range of sizes:
                        </p>
                        <p>
                          Our standard pallets have a base size of 1.2m x 1.2m.
                        </p>
                        <p>
                          The maximum tail lift delivery is 1000kg, however
                          please bear in mind if the goods require a small
                          vehicle the limit may be lower.
                        </p>
                        <p>
                          The maximum weight for fork lifts is as standard
                          1200kg, however if your pallet weighs more than this,
                          please contact us.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-41"
                      aria-expanded="false"
                    >
                      WHAT IF MY GOODS DON'T FIT A STANDARD PALLET?
                    </h6>
                    <div
                      id="faq-item-2-41"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          Don't worry! We move everything from coal to trees,
                          contact us at info@hastingsfreight.co.uk to find a
                          solution that's right for you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <h4>PALLET DELIVERY</h4>
                <div id="accordion_3">
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-5"
                      aria-expanded="false"
                    >
                      WHERE WILL THE GOODS BE PLACED AT THE DELIVERY POINT?
                    </h6>
                    <div
                      id="faq-item-2-5"
                      className="collapse"
                      data-bs-parent="#accordion_3"
                    >
                      <div className="card-body">
                        <p>
                          Deliveries are made to kerbside on a suitable hard
                          standing. The majority of deliveries will be made via
                          a tail lift and pallet truck.
                        </p>
                        <p>
                          Each driver carries out a dynamic risk assessment upon
                          delivery to aid in this process.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-6"
                      aria-expanded="false"
                    >
                      WHEN DO YOU DELIVER?
                    </h6>
                    <div
                      id="faq-item-2-6"
                      className="collapse"
                      data-bs-parent="#accordion_3"
                    >
                      <div className="card-body">
                        <p>
                          Being part of a network means we can offer our
                          customers delivery times to suit their needs.
                        </p>
                        <p>
                          Standard deliveries are between 9am and 5pm Monday to
                          Friday, however we also offer specific timed, evening
                          and Saturday services
                        </p>
                        <p>
                          Customers will be provided with a 2 hour eta window if
                          shipped through Palletways. All consignments can be
                          tracked through your personal online portal
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-7"
                      aria-expanded="false"
                    >
                      HOW DO I TRACK MY DELIVERY?
                    </h6>
                    <div
                      id="faq-item-2-7"
                      className="collapse"
                      data-bs-parent="#accordion_3"
                    >
                      <div className="card-body">
                        <p>
                          When setting up an account with Hastings Freight you
                          will be given access to an online portal. This shows
                          all consignments and their up-to-date status,
                          including two-hour ETA delivery windows. Once
                          delivered, you will be able to access the proof of
                          delivery.
                        </p>
                        <p>
                          Your customers can also have ETA direct to them,
                          providing a more seamless customer experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card */}
                <div className="card">
                  <h6
                    className="collapsed ltn__card-title"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-item-2-8"
                    aria-expanded="false"
                  >
                    HOW QUICK WILL MY DELIVERY ARRIVE?
                  </h6>
                  <div
                    id="faq-item-2-8"
                    className="collapse"
                    data-bs-parent="#accordion_3"
                  >
                    <div className="card-body">
                      <p>
                        Flexibility and value is key in logistics. As standard
                        we offer next day delivery or upto 48 hours services.
                        These can be tailored with our timed, evening and
                        Saturday slots. These service are available across the
                        majority of the UK and full details are available on
                        your personal portal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="need-support text-center mt-100">
                  <h2>Still need help?</h2>
                  <div className="btn-wrapper mb-30 go-top">
                    <Link to="/contact" className="theme-btn-1 btn">
                      Contact Us
                    </Link>
                  </div>
                  <h3>
                    <i className="fas fa-phone" /> 0845 673 2030
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar-area ltn__right-sidebar">
                <div className="widget ltn__banner-widget go-top">
                  <img
                    src={publicUrl + "assets/img/others/faqs.jpg"}
                    alt="Banner Image"
                  />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqV1;
