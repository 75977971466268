import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useState } from "react";

function SearchForm() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  const [post, setPost] = useState({
    location: "",
    size: "",
    weight: "",
  });

  function handleChange(event) {
    var e = document.getElementById(event.target.id);
    setPost({ ...post, [event.target.name]: e.options[e.selectedIndex].value });
  }

  return (
    <div className="ltn__car-dealer-form-area mt--65 mt-120 pb-115---">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__car-dealer-form-tab">
              <div className="tab-content bg-white box-shadow-1 position-relative pb-10">
                <div
                  className="tab-pane fade active show"
                  id="ltn__form_tab_1_1"
                >
                  <div className="car-dealer-form-inner">
                    <form action="#" className="ltn__car-dealer-form-box row">
                      <div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-3 col-md-6">
                        <select
                          id="ddlocation"
                          name="location"
                          onClick={handleChange}
                          value={post.location}
                        >
                          <option value="rtr">Choose Destination</option>
                          <option value="UK">UK</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Europe">Europe</option>
                          <option value="Global">Global</option>
                        </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-meter---- col-lg-3 col-md-6">
                        <select
                          id="ddsize"
                          name="size"
                          onClick={handleChange}
                          value={post.size}
                        >
                          <option value="rter">Pallet size</option>
                          <option value="Full">Full</option>
                          <option value="Half">Half</option>
                          <option value="Quarter">Quarter</option>
                          <option value="Oversize">Oversize</option>
                        </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-calendar---- col-lg-3 col-md-6">
                        <select
                          id="ddweight"
                          name="weight"
                          onClick={handleChange}
                          value={post.weight}
                        >
                          <option value="ret">Pallet weight</option>
                          <option value="Max250">0-250kg</option>
                          <option value="Max500">251-500kg</option>
                          <option value="Max1000">501-1000kg</option>
                          <option value="Over1000">1000+kg</option>
                        </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                        <div className="btn-wrapper text-center mt-0 go-top">
                          {/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
                          <Link
                            to={{
                              pathname: "/get-quote",
                              state: {
                                post,
                              },
                            }}
                            className="btn theme-btn-1 btn-effect-1 text-uppercase"
                          >
                            Quote Now
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchForm;
