import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import axios from "axios";
import { Redirect } from "react-router-dom";

class BlogSlider extends Component {
  state = {
    news: [],
    isLoaded: false,
  };

  componentDidMount() {
    axios
      .get(
        "http://www.hastingsfreight.co.uk/index.php/wp-json/wp/v2/posts?_embed&per_page=5"
      )
      .then((res) =>
        this.setState({
          books: res.data,
          isLoaded: true,
        })
      )
      .catch((err) => console.log(err));
  }

  render() {
    const { books, isLoaded } = this.state;

    function recHasProp(obj, prop) {
      for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (p === prop) {
            return obj;
          } else if (obj[p] instanceof Object && recHasProp(obj[p], prop)) {
            return obj[p];
          }
        }
      }
      return null;
    }

    let publicUrl = process.env.PUBLIC_URL + "/";
    let customClass = this.props.customClass ? this.props.customClass : "";
    let sectionClass = this.props.sectionClass ? this.props.sectionClass : "";
    if (isLoaded) {
      return (
        <div
          className={"ltn__blog-area pt-115--- pb-70 go-top " + sectionClass}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6
                    className={
                      "section-subtitle ltn__secondary-color " + customClass
                    }
                  >
                    News &amp; Blogs
                  </h6>
                  <h1 className="section-title">Latest News</h1>
                </div>
              </div>
            </div>
            <div className="row  ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
              {books.map((book) => (
                <div className="col-lg-12">
                  <div className="ltn__blog-item ltn__blog-item-3">
                    <div className="ltn__blog-img">
                      <Link to="/blog-details">
                        <img
                          src={
                            recHasProp(book, "wp:featuredmedia")
                              ? book._embedded["wp:featuredmedia"][0].source_url
                              : "1.jpg"
                          }
                          alt="#"
                        />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <div className="ltn__blog-meta">
                        <ul>
                          <li className="ltn__blog-author">
                            <Link to="/">
                              <i className="far fa-user" />
                              by: {book._embedded.author[0].name}
                            </Link>
                          </li>
                          <li className="ltn__blog-tags">
                            <Link to="/">
                              <i className="fas fa-tags" />
                              Charity
                            </Link>
                            <Link to="/">
                              <i className="fas fa-tags" />
                              Staff
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="ltn__blog-title">
                        <Link to="/blog-details">{book.title.rendered}</Link>
                      </h3>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-meta">
                          <ul>
                            <li className="ltn__blog-date">
                              <i className="far fa-calendar-alt" />
                              {book.date}
                            </li>
                          </ul>
                        </div>
                        <div className="ltn__blog-btn">
                          <Link to={"/blog-details/" + book.id}>Read more</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* Blog Item */}
            </div>
          </div>
        </div>
      );
    }
    return <h3>Loading...</h3>;
  }
}

export default BlogSlider;
