import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceInternationalBoxes extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  What we offer
                </h6>
                <h1 className="section-title">Why choose Hastings Freight?</h1>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/int_fast.jpg"}
                      alt="Hastings workers planning a route"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Fast and efficient delivery service</h3>
                  <p>Online order processing made simple</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/int_local.jpg"}
                      alt="Hastings truck with tail lift"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Local knowledge on the ground, home &amp; abroad</h3>
                  <p>Specialist in palletised freight distribution</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={
                        publicUrl + "assets/img/service/boxes/int_export.jpg"
                      }
                      alt="Hasting truck on the road"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Import and Export Clearance provided</h3>
                  <p>With direct ETA's to your customers</p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/tracking.jpg"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>First class insurance available for all pallets</h3>
                  <p>
                    Here is some text about why we are the best choice for our
                    48 hour delivery service
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/eta_app.jpg"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Customs and freight processing as standard</h3>
                  <p>
                    Here is some text about why we are the best choice for our
                    48 hour delivery service
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <img
                      src={publicUrl + "assets/img/service/boxes/building.jpg"}
                      alt="#"
                    />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>Clean trucks and safe drivers</h3>
                  <p>
                    Here is some text about why we are the best choice for our
                    48 hour delivery service
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceInternationalBoxes;
