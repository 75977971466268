import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
  Link,
} from "react-router-dom";
import Home from "./components/home";
import ServiceUKPage from "./components/service-uk";
import ServiceInternationalPage from "./components/service-international";
import ServiceAccountPage from "./components/service-account";
import ServiceTransportPage from "./components/service-transport";
import GetStartedPage from "./components/get-started";
import GeneralEnquiryPage from "./components/general-enquiry";
import GetQuotePage from "./components/get-quote";
import Team from "./components/team";
import AboutUsPage from "./components/about-us";
import AboutUsYourCareerPage from "./components/about-us-your-career";
import Faq from "./components/faq";
import Error from "./components/404";
import BlogGrid from "./components/blog-grid";
import BlogDetails from "./components/blog-details";
import Contact from "./components/contact";
import Careers from "./components/careers";
import CookieConsent from "react-cookie-consent";
import PrivacyPolicyPage from "./components/privacy-policy";

class Root extends Component {
  render() {
    return (
      <HashRouter basename="/">
        <div>
          <Switch>
            <Route exact path="/home" component={Home} />
            <Route exact path="/" component={Home} />

            {/* About Pages */}
            <Route path="/about" component={AboutUsPage} />
            <Route path="/your-career" component={AboutUsYourCareerPage} />
            <Route path="/team" component={Team} />
            <Route path="/faq" component={Faq} />

            {/* Service Pages */}
            <Route path="/service" component={ServiceUKPage} />
            <Route path="/service-uk" component={ServiceUKPage} />
            <Route path="/service-transport" component={ServiceTransportPage} />
            <Route path="/service-accounts" component={ServiceAccountPage} />
            <Route
              path="/service-international"
              component={ServiceInternationalPage}
            />

            {/* Form Pages */}
            <Route path="/get-started" component={GetStartedPage} />
            <Route path="/general-enquiry" component={GeneralEnquiryPage} />
            <Route path="/get-quote" component={GetQuotePage} />

            {/* News Pages */}
            <Route path="/news" component={BlogGrid} />
            <Route path="/news-detail/:id" component={BlogDetails} />

            {/* Other Pages */}
            <Route path="/careers" component={Careers} />
            <Route path="/contact" component={Contact} />
            <Route path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route path="/404" component={Error} status="404" />
            <Route path="*" component={Error} status="404" />
          </Switch>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="I Accept"
          cookieName="hastingscookieaccept"
          style={{ background: "#153674" }}
          buttonStyle={{ backgroundColor: "#95C32C" }}
          declineButtonStyle={{ backgroundColor: "#153674" }}
          // declineButtonText="Read More"
          // setDeclineCookie={false}
          // enableDeclineButton
          // flipButtons
        >
          COOKIES AND PRIVACY POLICY<br></br>
          <span style={{ fontSize: "0.9em" }}>
            This website uses cookies to improve your experience. By continuing
            to use this site you are accepting you are ok with this.{" "}
          </span>
          <Link to="/privacy-policy" style={{ fontWeight: "bold" }}>
            Read our Privacy Policy here
          </Link>
        </CookieConsent>
      </HashRouter>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
