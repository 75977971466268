import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class BannerHome extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__slider-area ltn__slider-3  section-bg-2">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
          {/* ltn__slide-item */}
          <div
            className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60"
            data-bs-bg={publicUrl + "assets/img/slider/home_1.jpg"}
          >
            <div className="ltn__slide-item-inner  text-left">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <h6 className="slide-sub-title animated">
                          <span>
                            <i className="fas fa-truck" />
                          </span>{" "}
                          Hastings Freight Ltd
                        </h6>
                        <h1 className="slide-title animated ltn__lumo-color">
                          Fast <br />
                        </h1>
                        <h2 className="animated">Next day deliveries</h2>
                        <div className="slide-brief animated">
                          <p>Exceptional logistics and distribution service</p>
                        </div>
                        <div className="btn-wrapper animated go-top">
                          <Link
                            to="/service-uk"
                            className="theme-btn-1 btn btn-effect-1"
                          >
                            Find Out More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60"
            data-bs-bg={publicUrl + "assets/img/slider/home_2.jpg"}
          >
            <div className="ltn__slide-item-inner  text-left">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <h6 className="slide-sub-title animated">
                          <span>
                            <i className="fas fa-truck" />
                          </span>{" "}
                          Hastings Freight Ltd
                        </h6>
                        <h1 className="slide-title animated ltn__lumo-color">
                          Reliable
                        </h1>
                        <h2 className="animated">
                          Services across UK &amp; Europe
                        </h2>
                        <div className="slide-brief animated">
                          <p>Exceptional logistics and distribution service</p>
                        </div>
                        <div className="btn-wrapper animated go-top">
                          <Link
                            to="/service-international"
                            className="theme-btn-1 btn btn-effect-1"
                          >
                            Find Out More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60"
            data-bs-bg={publicUrl + "assets/img/slider/home_3.jpg"}
          >
            <div className="ltn__slide-item-inner  text-left">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <h6 className="slide-sub-title animated">
                          <span>
                            <i className="fas fa-truck" />
                          </span>{" "}
                          Hastings Freight Ltd
                        </h6>
                        <h1 className="slide-title animated ltn__lumo-color">
                          Bespoke
                        </h1>
                        <h1 className="animated ">
                          Solutions for your transport needs
                        </h1>
                        <div className="slide-brief animated">
                          <p>Exceptional logistics and distribution service</p>
                        </div>
                        <div className="btn-wrapper animated go-top">
                          <Link
                            to="/service-transport"
                            className="theme-btn-1 btn btn-effect-1"
                          >
                            Find Out More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60"
            data-bs-bg={publicUrl + "assets/img/slider/home_4.jpg"}
          >
            <div className="ltn__slide-item-inner  text-left">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <h6 className="slide-sub-title animated">
                          <span>
                            <i className="fas fa-truck" />
                          </span>{" "}
                          Hastings Freight Ltd
                        </h6>
                        <h1 className="slide-title animated ltn__lumo-color">
                          Customer Driven
                        </h1>
                        <h2 className="animated">Putting you in control</h2>

                        <div className="slide-brief animated">
                          <p>Exceptional logistics and distribution service</p>
                        </div>
                        <div className="btn-wrapper animated go-top">
                          <Link
                            to="/service-accounts"
                            className="theme-btn-1 btn btn-effect-1"
                          >
                            Find Out More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    );
  }
}

export default BannerHome;
