import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";

class AboutUsYourCareerContent extends Component {

  state = { jobs: [], isLoaded: false, numPages: 0 };

  componentDidMount() {
    axios
      .get('https://hastingsapi.wangbodev.co.uk/jobs')
      .then((res) => {
        this.setState({
          jobs: res.data,
          isLoaded: true,
          numPages: 1,
        });
        console.log(this.state);
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { jobs, isLoaded, numPages } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";

    
  if (isLoaded){
    return (
      <div className="ltn__team-details-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__team-details-member-info text-center mb-40">
                <div className="team-details-img">
                  <img
                    src={publicUrl + "assets/img/others/opportunities.jpg"}
                    alt="Team Member Image"
                  />
                </div>
                <h2>Hastings Freight Ltd</h2>
                <h6 className="text-uppercase ltn__secondary-color">
                  Sheffield, United Kingdom
                </h6>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="ltn__team-details-member-info-details">
                <h3>What can we offer</h3>
                <p>Your career starts with us!</p>
                <p>
                  We are a busy family run business that believes in leaving a
                  lasting, positive impact on our environment, community, and
                  customers.
                </p>
                <p>
                  If you're passionate about making a difference and working as
                  part of a team, we're looking for you!
                </p>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    Want to find out more what it's like to work at Hastings
                    Freight?{" "}
                    <Link to="/careers" className="highlightLink">
                      Click Here
                    </Link>{" "}
                    to see what our team says.
                  </p>
                </div>
                <h3>Current Vacancies</h3>
                <p>
                  We're always looking for new, enthusiastic people to join our
                  work force. If this sounds like you then send us a copy of
                  your CV to hr@hastingsfreight.co.uk
                </p>
                <p>
                  We do sometimes have specific roles we are actively recruiting
                  for and these will appear below when we're looking to fill a
                  specific gap in our team so keep checking back
                </p>
              </div>
              <div className="row ltn__product-slider-item-three-active--- slick-arrow-1">
                
                {jobs.map((job) => 
                <div className="col-xl-6 col-sm-6 col-12">
                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                  <div className="product-img go-top">
                    <img
                      src={publicUrl + "assets/img/team/hiring.jpg"}
                      alt="#"
                    />
                  </div>
                  <div className="product-info">
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badg">{job.term}</li>
                      </ul>
                    </div>
                    <h2 className="product-title go-top">
                    <a href={job.applylink} target="_blank" className="highlightLink">
                      {job.title}
                      </a>
                    </h2>
                    <div className="product-img-location">
                      <ul>
                        <li>
                          <i className="flaticon-pin" /> {job.location}
                        </li>
                      </ul>
                    </div>
                    <br />
                    <p>
                    {job.content}
                    {" "}<br />
                    <a href={job.applylink} target="_blank" className="highlightLink">APPLY HERE</a>
                    </p>
                  </div>
                  <div className="product-info-bottom">
                    <div className="product-price">
                      <span>
                        {job.wage} <label> {job.wageterm}</label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>                
            )}

              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
    );
  }
    return (
      <div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
        <div className="container">
          <div className="row">
            <center>
              <img
                src={publicUrl + "assets/img/icons/icon-img/loading.gif"}
                alt="#"
              ></img>
              <h3>Loading...</h3>;
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsYourCareerContent;
