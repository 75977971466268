import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import Copyright from "./copyright";
import { useState, useEffect } from "react";
import axios from "axios";

function Footer_v1() {
  let apiurl = "https://hastings.mailshipper.co.uk";

  useEffect(() => {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }, []);

  // }

  const [post, setPost] = useState({
    email: "",
    submitMessage: "",
    formvalid: "",
  });

  function validateForm() {
    var emailValid = String(post.email).match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    console.log(emailValid);
    setPost({
      submitMessage: "Email is invalid, please correct",
      formvalid: emailValid,
    });
  }

  function handleChange(event) {
    setPost({ ...post, [event.target.name]: event.target.value });
  }

  function handleSubmit(event) {
    event.preventDefault();
    validateForm();
    if (post.formvalid) {
      axios
        .post(apiurl + "/sendNewsletterMail", {
          post,
        })
        .then((response) =>
          setPost({
            email: "",
            submitMessage: "Success! Thankyou for keeping up to date!",
          })
        )
        .catch((err) => console.log(err));
    }
  }

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "Footer logo";

  return (
    <footer className="ltn__footer-area  ">
      <div className="footer-top-area  section-bg-2 plr--5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div className="site-logo">
                    <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                  </div>
                </div>
                <p>
                  Hastings Freight is a company you can trust with your supply
                  chain. Keeping your customers happy is our business, because
                  logistics is not just about transport.
                </p>
                <div className="footer-address">
                  <ul>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-placeholder" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          10 High Hazels Road, Barlborough Links, Chesterfield,
                          S43 4UZ
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-call" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel:0845 673 2030">0845 673 2030</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-mail" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:info@hastingsfreight.co.uk">
                            info@hastingsfreight.co.uk
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="ltn__social-media mt-20">
                  <Social />
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Company</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/news">News</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Services</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/service-uk">UK & ROI Freight</Link>
                    </li>
                    <li>
                      <Link to="/service-international">
                        International Freight
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-transport">Bespoke solutions</Link>
                    </li>
                    <li>
                      <Link to="/service-accounts">
                        Online Account Management
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Customer Care</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/get-quote">Quotations</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    <li>
                      <a
                        href={process.env.PUBLIC_URL + "/termsconditions.pdf"}
                        target="_blank"
                      >
                        T&Cs
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-sm-12 col-12">
              <div className="footer-widget footer-newsletter-widget">
                <h4 className="footer-title">Keep up to date!</h4>
                <p>
                  Subscribe to our seasonal updates and receive news via email.
                </p>
                <div className="footer-newsletter">
                  <form id="newsletter" onSubmit={handleSubmit}>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      onChange={handleChange}
                    />
                    <div className="btn-wrapper">
                      <button className="theme-btn-1 btn" type="submit">
                        <i className="fas fa-location-arrow" />
                      </button>
                    </div>
                  </form>
                  <label name="emailerr" className="ltn__lumo-color">
                    {post.submitMessage}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}

export default Footer_v1;
