import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import axios from "axios";
import { Redirect } from "react-router-dom";

class BlogSlider extends Component {
  state = {
    news: [],
    isLoaded: false,
  };

  componentDidMount() {
    axios
      .get(process.env.PUBLIC_URL + "/news.json")
      .then((res) =>
        this.setState({
          news: res.data,
          isLoaded: true,
        })
      )
      .catch((err) => console.log(err));
  }

  render() {
    const { news, isLoaded } = this.state;

    let publicUrl = process.env.PUBLIC_URL + "/";
    let customClass = this.props.customClass ? this.props.customClass : "";
    let sectionClass = this.props.sectionClass ? this.props.sectionClass : "";
    return (
      <div className={"ltn__blog-area pt-115--- pb-70 go-top " + sectionClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6
                  className={
                    "section-subtitle ltn__secondary-color " + customClass
                  }
                >
                  News &amp; Blogs
                </h6>
                <h1 className="section-title">Latest News</h1>
              </div>
            </div>
          </div>
          <div className="row  ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
            {/* Blog Item */}
            {news.map((newsitem) => (
              <div className="col-lg-12" key={newsitem.id}>
                <div className="ltn__blog-item ltn__blog-item-3">
                  <div className="ltn__blog-img">
                    <Link to={"/news-detail/" + newsitem.id}>
                      <img src={"/assets/img/news/" + newsitem.image} alt="#" />
                    </Link>
                  </div>
                  <div className="ltn__blog-brief">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-author">
                          <i className="far fa-user" />
                          {newsitem.author}
                        </li>
                        {/* <li className="ltn__blog-tags">
                          <Link to="/">
                            <i className="fas fa-tags" />
                            Charity
                          </Link>
                          <Link to="/">
                            <i className="fas fa-tags" />
                            Staff
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                    <h3 className="ltn__blog-title">
                      <Link to={"/news-detail/" + newsitem.id}>
                        {newsitem.title}
                      </Link>
                    </h3>
                    <div className="ltn__blog-meta-btn">
                      <div className="ltn__blog-meta">
                        <ul>
                          <li className="ltn__blog-date">
                            <i className="far fa-calendar-alt" />
                            {newsitem.printdate}
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__blog-btn">
                        <Link to={"/news-detail/" + newsitem.id}>
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogSlider;
