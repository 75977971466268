import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutUsContent extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__team-details-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__team-details-member-info text-center mb-40">
                <div className="team-details-img">
                  <img
                    src={publicUrl + "assets/img/others/about_box.jpg"}
                    alt="Team Member Image"
                  />
                </div>
                <h2>Hastings Freight Ltd</h2>
                <h6 className="text-uppercase ltn__secondary-color">
                  Sheffield, United Kingdom
                </h6>
                {/* <div className="ltn__social-media-3">
                  <ul>
                    <li>
                      <a href="#" title="Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Linkedin">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="ltn__team-details-member-info-details">
                <p>
                  Before looking forward it's always important to know where
                  you've come from, and our business is no exception. Hastings
                  Freight was established in 1983 by Alan Hastings, operating
                  with a small team as a general haulier.
                </p>
                <p>
                  In 1994 we joined the newly formed Palletways network,
                  providing a cost-effective solution to transport for companies
                  and individuals based within South Yorkshire and Derbyshire,
                  specialising in palletised distribution.
                </p>
                <p>
                  Rapidly expanding over the past 20 years, we moved to our new
                  site situated off junction 30 on the M1, joining our second
                  network UPN in 2021, where we've gone from strength to
                  strength.
                </p>
                <p>
                  After working with the team for over 16 years, the company was
                  bought by husband and wife team George and Claire Hewitt.
                </p>

                <h3>Our mission</h3>
                <p>
                  To provide a hassle free, tailored solution to your
                  transportation needs.
                </p>
                <div className="row ltn__custom-gutter mt-50 mb-20">
                  <div className="col-xl-4 col-sm-6 col-12 go-top">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center">
                      <div className="ltn__feature-icon">
                        <span>
                          <img
                            className="circleimg"
                            src={
                              publicUrl + "assets/img/icons/icon-img/env.jpg"
                            }
                            alt="#"
                          />
                        </span>
                      </div>
                      <div className="ltn__feature-info">
                        <h4>Positive Contribution</h4>
                        <p>
                          Leaving a positive, lasting impact on our environment
                          and community
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center active">
                      <div className="ltn__feature-icon">
                        <span>
                          <img
                            className="circleimg"
                            src={
                              publicUrl +
                              "assets/img/icons/icon-img/business.jpg"
                            }
                            alt="#"
                          />
                        </span>
                      </div>
                      <div className="ltn__feature-info go-top">
                        <h4>
                          <Link to="/team">
                            Distribution <br /> made easy
                          </Link>
                        </h4>
                        <p>
                          Our experienced team are on hand to support you at
                          every point
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center">
                      <div className="ltn__feature-icon">
                        <span>
                          <img
                            className="circleimg"
                            src={
                              publicUrl +
                              "assets/img/icons/icon-img/tracking.jpg"
                            }
                            alt="#"
                          />
                        </span>
                      </div>
                      <div className="ltn__feature-info go-top">
                        <h4>
                          <Link to="/service-details">
                            Innovative <br />
                            Technology
                          </Link>
                        </h4>
                        <p>
                          Easy online booking, tracking and ETA direct to your
                          customer
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h3>Our networks</h3>
                <p>
                  We move everything from coal to trees to small boxes and are
                  proud to work within the palletised freight industry.
                </p>
                <p>
                  We provide flexible, same day collections from you, to your
                  customers overnight.
                </p>
                <p>
                  In 1994 we became a founding member of Palletways, benefitting
                  from access to Europe wide markets and innovative tech
                  advances making managing your freight simple.
                </p>
                <p>
                  In 2021 we joined the United Pallet Network and expanded our
                  scope as Pallet Specialists
                </p>
                <div className="btn-wrapper animated go-top">
                  <Link to="/contact" className="theme-btn-1 btn btn-effect-1">
                    Contact Us
                  </Link>
                </div>
                {/* <div className="ltn__form-box contact-form-box box-shadow white-bg mt-50">
                  <h4 className="title-2">Contact for any Inquiry</h4>
                  <form id="contact-form" action="mail.php" method="post">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-email ltn__custom-icon">
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter email address"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item">
                          <select className="nice-select">
                            <option>Select Service Type</option>
                            <option>UK Frieght </option>
                            <option>European Freight </option>
                            <option>Global Freight</option>
                            <option>Online Tools</option>
                            <option>Careers</option>
                            <option>Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-phone ltn__custom-icon">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Enter phone number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="message"
                        placeholder="Enter message"
                        defaultValue={""}
                      />
                    </div>
                    <p>
                      <label className="input-info-save mb-0">
                        <input type="checkbox" name="agree" /> Save my name,
                        email, and website in this browser for the next time I
                        comment.
                      </label>
                    </p>
                    <div className="btn-wrapper mt-0">
                      <button
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                        type="submit"
                      >
                        submit enquiry
                      </button>
                    </div>
                    <p className="form-messege mb-0 mt-20" />
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsContent;
